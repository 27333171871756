<template>
<div>
  <div class="row" v-if="language === 'es'">
    <div class="col-12">
      He visto que quieres <strong>mejorar la calidad de tus datos</strong> ;)<br>
      ¿Sabes que con uProc puedes <strong>limpiar, comprobar y enriquecer</strong> cualquier dato que tengas?
      <br><br>
      Consíguelo en tres sencillos pasos:
      <ul>
        <li><strong><a href="/#/wizard/file">Sube</a></strong> tu fichero desde el asistente.</li>
        <li><strong>Elije</strong> una de las herramientas propuestas (o utiliza el asistente avanzado) y procesa tu fichero.</li>
        <li><strong>Recibe</strong> los resultados por correo.</li>
      </ul>
      Recuerda que también puedes mejorar tus datos desde:
      <strong><a href="https://uproc.io/blog/es/how-to-check-if-email-exists-extended#sheets" target="_blank">Google Sheets</a></strong>,
      <strong><a href="https://uproc.io/blog/es/how-to-check-if-email-exists-extended#n8n" target="_blank">n8n</a></strong>,
      <strong><a href="https://uproc.io/blog/es/how-to-check-if-email-exists-extended#make" target="_blank">Make</a></strong>,
      <strong><a href="https://uproc.io/blog/es/how-to-check-if-email-exists-extended#zapier" target="_blank">Zapier</a></strong> o
      <strong><a href="https://uproc.io/blog/es/how-to-check-if-email-exists-extended#api" target="_blank">API</a></strong>.
      <br>Elije el método que mejor se adapta a tus necesidades.
    </div>
    <!-- <div class="col-12">
      <div class="row">
        <div class="col-lg-4">
          ¿Quieres ver un ejemplo rápido? Revisa este video tutorial para ver la herramienta de verificación de correos en acción utilizando Google Sheets:
        </div>
        <div class="col-lg-8">
          <div class="video-responsive">
            <iframe width="100%" height="360" src="https://www.youtube.com/embed/f2IudtgqDRE?VQ=HD720" frameborder="0" allowfullscreen="allowfullscreen"></iframe>
          </div>
        </div>
      </div>
    </div> -->
  </div>
  <div class="row" v-else>
    <div class="col-12">
      I see that you want to <strong>improve the quality of your data</strong> ;)<br>
      Did you know that with uProc you can <strong>clean, verify, and enrich</strong> any data you have?
      <br><br>
      Achieve it in three simple steps:
      <ul>
        <li><strong><a href="/#/wizard/file" >Upload</a></strong> your file from the wizard.</li>
        <li><strong>Choose</strong> one of the proposed tools (or use the advanced wizard) and process your file.</li>
        <li><strong>Receive</strong> the results by email.</li>
      </ul>
      Remember that you can also improve your data from:
      <strong><a href="https://uproc.io/blog/en/how-to-check-if-email-exists-extended#sheets" target="_blank">Google Sheets</a></strong>,
      <strong><a href="https://uproc.io/blog/en/how-to-check-if-email-exists-extended#n8n" target="_blank">n8n</a></strong>,
      <strong><a href="https://uproc.io/blog/en/how-to-check-if-email-exists-extended#make" target="_blank">Make</a></strong>,
      <strong><a href="https://uproc.io/blog/en/how-to-check-if-email-exists-extended#zapier" target="_blank">Zapier</a></strong> or
      <strong><a href="https://uproc.io/blog/en/how-to-check-if-email-exists-extended#api" target="_blank">API</a></strong>.
      <br>Choose the method that best suits your needs.
    </div>
    <!-- <div class="col-12">
      <div class="row">
        <div class="col-lg-4">
          ¿Quieres ver un ejemplo rápido? Revisa este video tutorial para ver la herramienta de verificación de correos en acción utilizando Google Sheets:
        </div>
        <div class="col-lg-8">
          <div class="video-responsive">
            <iframe width="100%" height="360" src="https://www.youtube.com/embed/f2IudtgqDRE?VQ=HD720" frameborder="0" allowfullscreen="allowfullscreen"></iframe>
          </div>
        </div>
      </div>
    </div> -->
  </div>
</div>
</template>

<script>
export default {
  name: 'WelcomeData',
  data() {
    return {
      language: this.$i18n.locale
    };
  },
  created() {

  },
  mounted() {}
}
</script>
