<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="pb-4">
        <a v-on:click="back">
          {{ $t("back") }}
        </a>
      </div>
      <div class="text-left" style="background-color: #fff; padding: 20px">
        <h4>{{ $t("settings") }}</h4>
        <div class="profile-settings text-xs" v-if="user">
          <h6 class="pt-4">
            {{ $t("lists-cost") }}:
            {{ $t("lists-cost-cpm", { cpm: list_entry_cost / 10 }) }}
          </h6>
          <br />

          <h6>{{ $t("lists-filters") }}</h6>
          <div class="total-checkbox">
            <div class="check-one col-lg-12">
              <label class="col-sm-2 col-2 no_side_pad">
                <input
                  type="checkbox"
                  class="control control-checkbox"
                  name="optradio"
                  v-model="user.lists_only_person_with_email"
                  v-on:change="hasToBeSaved()"
                />
              </label>
              <div class="text col-lg-10 no_side_pad">
                {{ $t("lists-only-person-with-email") }}
              </div>
            </div>

            <div
              class="check-one col-lg-12"
              v-show="user.lists_only_person_with_email"
            >
              <label class="col-sm-2 col-2 ml-4 display-inline">
                <input
                  type="checkbox"
                  name="optradio"
                  v-model="user.lists_only_person_with_verified_email"
                  v-on:change="
                    hasToBeSaved();
                    markVerifyIfRequired();
                    updateEntryCost();
                  "
                />
              </label>
              <div class="text col-sm-10 col-10">
                {{ $t("lists-only-person-with-verified-email") }}
              </div>
            </div>

            <div class="check-one col-lg-12">
              <label class="col-sm-1 col-1 no_side_pad">
                <input
                  type="checkbox"
                  name="optradio"
                  v-model="user.lists_only_person_with_phone"
                  v-on:change="hasToBeSaved()"
                />
              </label>
              <div class="text col-sm-11 col-11 no_side_pad">
                {{ $t("lists-only-person-with-phone") }}
              </div>
            </div>

            <div class="check-one col-lg-12">
              <label class="col-sm-1 col-1 no_side_pad">
                <input
                  type="checkbox"
                  class="control control-checkbox"
                  name="optradio"
                  v-model="user.lists_only_person_with_personal_email"
                  v-on:change="
                    hasToBeSaved();
                    markPersonalEmailIfRequired();
                  "
                />
              </label>
              <div class="text col-sm-11 col-11 no_side_pad">
                {{ $t("lists-only-person-with-personal-email") }}
              </div>
            </div>

            <div class="check-one col-lg-12">
              <label class="col-sm-1 col-1 no_side_pad">
                <input
                  type="checkbox"
                  class="control control-checkbox"
                  name="optradio"
                  v-model="user.lists_only_person_with_personal_phone"
                  v-on:change="
                    hasToBeSaved();
                    markPersonalPhoneIfRequired();
                  "
                />
              </label>
              <div class="text col-sm-11 col-11 no_side_pad">
                {{ $t("lists-only-person-with-personal-phone") }}
              </div>
            </div>

            <div class="check-one col-lg-12">
              <label class="col-sm-1 col-1 no_side_pad">
                <input
                  type="checkbox"
                  class="control control-checkbox"
                  name="optradio"
                  v-model="user.lists_only_person_with_business_type"
                  v-on:change="
                    hasToBeSaved();
                    markBusinessTypeIfRequired();
                  "
                />
              </label>
              <div class="text col-sm-11 col-11 no_side_pad">
                {{ $t("lists-only-person-with-business-type") }}
                <select
                  class="form-control-sm col-lg-1"
                  v-model="user.lists_only_person_with_business_type_value"
                  v-on:change="hasToBeSaved()"
                >
                  <option value="B2B" selected>B2B</option>
                  <option value="B2C">B2C</option>
                </select>
              </div>
            </div>

            <br />
            <div class="check-one col-lg-12">
              <label class="col-sm-1 col-1 no_side_pad">
                <input
                  type="checkbox"
                  name="optradio"
                  v-model="user.lists_match_all_conditions"
                  v-on:change="hasToBeSaved()"
                />
              </label>
              <div class="text col-sm-11 col-11 no_side_pad">
                {{ $t("lists-match-all-conditions") }}
              </div>
            </div>
          </div>
          <br />
          <h6>{{ $t("lists-enrichment") }}</h6>
          <div class="total-checkbox">
            <div class="check-one col-lg-12">
              <label class="col-sm-1 col-1 no_side_pad">
                <input
                  type="checkbox"
                  class="control control-checkbox"
                  name="optradio"
                  v-model="user.lists_enrichment_personal_email"
                  v-on:change="
                    hasToBeSaved();
                    updateEntryCost();
                  "
                />
              </label>
              <div class="text col-sm-11 col-11 no_side_pad">
                {{ $t("lists-enrichment-personal-email") }}
              </div>
            </div>

            <div class="check-one col-lg-12">
              <label class="col-sm-1 col-1 no_side_pad">
                <input
                  type="checkbox"
                  class="control control-checkbox"
                  name="optradio"
                  v-model="user.lists_enrichment_personal_phone"
                  v-on:change="
                    hasToBeSaved();
                    updateEntryCost();
                  "
                />
              </label>
              <div class="text col-sm-11 col-11 no_side_pad">
                {{ $t("lists-enrichment-personal-phone") }}
              </div>
            </div>

            <div class="check-one col-lg-12">
              <label class="col-sm-1 col-1 no_side_pad">
                <input
                  type="checkbox"
                  class="control control-checkbox"
                  name="optradio"
                  v-model="user.lists_enrichment_web_visits"
                  v-on:change="
                    hasToBeSaved();
                    updateEntryCost();
                  "
                />
              </label>
              <div class="text col-sm-11 col-11 no_side_pad">
                {{ $t("lists-enrichment-web-visits") }}
              </div>
            </div>

            <div class="check-one col-lg-12">
              <label class="col-sm-1 col-1 no_side_pad">
                <input
                  type="checkbox"
                  class="control control-checkbox"
                  name="optradio"
                  v-model="user.lists_enrichment_business_type"
                  v-on:change="
                    hasToBeSaved();
                    updateEntryCost();
                  "
                />
              </label>
              <div class="text col-sm-11 col-11 no_side_pad">
                {{ $t("lists-enrichment-business-type") }}
              </div>
            </div>
          </div>

          <br />

          <h6>{{ $t("lists-verifications") }}</h6>
          <div class="total-checkbox">
            <div class="check-one col-lg-12">
              <label class="col-sm-1 col-1 no_side_pad">
                <input
                  type="checkbox"
                  class="control control-checkbox"
                  name="optradio"
                  v-model="user.lists_verify_email"
                  v-on:change="
                    hasToBeSaved();
                    updateEntryCost();
                  "
                />
              </label>
              <div class="text col-sm-11 col-11 no_side_pad">
                {{ $t("lists-verify-email") }}
              </div>
            </div>
          </div>
          <br />
          <!-- <h6>{{ $t('lists-blacklist') }} ({{ user.blacklist_size }})</h6>
                <div class="total-checkbox">
                  <div class="check-one col-lg-12">
                    <label class="col-sm-1 col-1 no_side_pad">
                      <input type="checkbox" class="control control-checkbox" name="optradio" v-model="user.lists_check_blacklist" v-on:change="hasToBeSaved()" />
                    </label>
                    <div class="text col-sm-11 col-11 no_side_pad">
                      {{ $t('lists-check-blacklist') }} (<a href="/#/settings/profile" v-on:click="downloadBlacklist()">{{ $t('lists-blacklist-download') }}</a>)
                    </div>
                  </div>
                </div>
                <br /> -->
          <h6>{{ $t("lists-webhook") }}</h6>
          <div class="total-checkbox text-left">
            <div class="check-one col-lg-8">
              <div class="text col-sm-8 col-8 no_side_pad">
                {{ $t("lists-webhook-description") }}
              </div>
              <label class="col-sm-8 col-8 no_side_pad">
                <input
                  type="text"
                  class="form-control"
                  id="name"
                  v-model="user.lists_webhook"
                  placeholder="https://sampledomain.com/mywebhook"
                  v-on:keyup="hasToBeSaved()"
                  v-on:click="$event.target.select()"
                  required
                />
              </label>
            </div>
            <div class="row">
              <div class="col-lg-12">
                <!-- <button type="button" class="btn btn-secondary btn-md cancel-notification" v-on:click="setSubsection('index')">{{ $t('cancel') }}</button> -->
                <button
                  :disabled="!this.hasToBeSent"
                  type="button"
                  class="btn btn-primary"
                  v-on:click="save()"
                >
                  {{ $t("save") }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "list_settings",
  data() {
    return {
      lists: [],
      list_sorted_asc: true,
      pendingRequest: false,
      editRow: -1,
      editMode: false,
      loading: true,

      user: null,

      list_entry_cost: 25,
      hasToBeSent: false,
    };
  },
  mounted() {
    this.getUserDetails();
  },
  components: {},
  methods: {
    back: function () {
      this.$emit("change-subsection", "index");
    },

    getConfigAuth: function () {
      return {
        headers: {
          Authorization:
            "Basic " +
            btoa(localStorage.configEmail + ":" + localStorage.token),
          "Content-Type": "application/json",
        },
      };
    },

    hasToBeSaved: function () {
      if (JSON.stringify(this.user) === JSON.stringify(this.FromServerUser)) {
        this.hasToBeSent = false;
      } else {
        this.hasToBeSent = true;
      }
    },

    save: function () {
      this.sending = true;
      var userData = {
        name: this.user.name,
        plan: this.user.plan,
        email: this.user.email,
        billing_email: this.user.billing_email,
        job_emails: this.user.job_emails,
        oscar_emails: this.user.oscar_emails,
        emails: this.user.emails,
        password: this.user.password,
        newsletter: this.user.newsletter,
        webpush: this.user.webpush,
        cards: this.user.cards,
        notifications: this.user.notifications,

        lists_enrichment_personal_email:
          this.user.lists_enrichment_personal_email,
        lists_enrichment_personal_phone:
          this.user.lists_enrichment_personal_phone,
        lists_enrichment_web_visits: this.user.lists_enrichment_web_visits,
        lists_enrichment_business_type:
          this.user.lists_enrichment_business_type,

        lists_only_person_with_verified_email:
          this.user.lists_only_person_with_verified_email,
        lists_only_person_with_email: this.user.lists_only_person_with_email,
        lists_only_person_with_personal_email:
          this.user.lists_only_person_with_personal_email,
        lists_only_person_with_personal_phone:
          this.user.lists_only_person_with_personal_phone,
        lists_only_person_with_phone: this.user.lists_only_person_with_phone,
        lists_only_person_with_business_type:
          this.user.lists_only_person_with_business_type,
        lists_only_person_with_business_type_value:
          this.user.lists_only_person_with_business_type_value,
        lists_verify_email: this.user.lists_verify_email,
        lists_check_blacklist: this.user.lists_check_blacklist,
        lists_match_all_conditions: this.user.lists_match_all_conditions,
        lists_webhook: this.user.lists_webhook,

        company: this.user.company,
        invoices: this.user.invoices,
        autopayments: this.user.autopayments,
        autopayments_charge: this.user.autopayments_charge,
        autopayments_balance: this.user.autopayments_balance,
        attach: this.user.attach,
        favorites: this.user.favorites,
        allowed_sources: this.user.allowed_sources,
        pages: this.user.pages,
        country_code: this.user.country_code,
        memberships: this.user.memberships,
        webhooks: this.user.webhooks,
      };

      this.$root.$emit("isLoading", true);
      var url = "/api/v2/profile";
      this.$http.post(url, userData, this.getConfigAuth()).then((results) => {
        this.user = results.data;
        this.newApplicationShown = false;
        this.FromServerUser = JSON.parse(JSON.stringify(results.data));
        this.sending = false;
        this.$toast.success(this.$t("saved-changes"));
        this.$root.$emit("isLoading", false);
        this.hasToBeSent = false;
      });
    },

    getUserDetails: function () {
      var url = "/api/v2/profile";
      this.$http.get(url, this.getConfigAuth()).then((results) => {
        this.user = results.data;
      });
    },
  },
};
</script>
