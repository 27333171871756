<template>
  <span>
    <font-awesome-icon v-if="value === true" icon="check-circle" class="text-success" size="lg" />
    <font-awesome-icon v-if="value === false" icon="times-circle" class="text-danger" size="lg" />
<!-- 
    <svg v-if="value === true" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="12" cy="12" r="12" fill="#0BE655" />
      <path d="M7 13L10 16L18 8" stroke="white" stroke-width="2" />
    </svg>

    <svg v-if="value === false" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="12" cy="12" r="12" fill="#F70C0C" />
      <path d="M7.70711 17.7071L17.7071 7.70711L16.2929 6.29289L6.29289 16.2929L7.70711 17.7071ZM17.7071 16.2929L7.70711 6.29289L6.29289 7.70711L16.2929 17.7071L17.7071 16.2929Z" fill="white"></path>
    </svg> -->

  </span>
</template>
<script>
export default {
  props: ['name', 'value']
};
</script>
