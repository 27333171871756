<template>
  <div class="tab-content">
    <div id="homeLists" class="tab-pane container active">
      <div class="col-lg-12 no_side_pad request">
        <div v-if="subsection == 'settings'">
          <ListsSettings :subsection="subsection" v-on:change-subsection="setSubsection" />
        </div>
        <div v-else-if="subsection == 'index'">
          <!-- <div class="col-lg-12 text-right">
            <button type="button" class="btn btn-secondary" @click="setSubsection('settings')">
              <font-awesome-icon icon="cog" size="xs" />
              {{ $t('settings') }}
            </button>
          </div> -->
          <div class="col-lg-12">
            <p v-html="$t('lists-description')"></p>
          </div>

          <div class="col-lg-12 pt-4">
            <a href="https://bit.ly/379yIjC" target="_blank">{{ $t('lists-sample-people' ) }}</a>
          </div>
                      
            <div class="create-new-list pb-4">
              <div class="row"> 
                <div class="col-lg-12 text-center">
                  <button type="button" class="btn btn-primary btn-lg add-selection add" :disabled="loading" v-on:click="addNew()">+ {{ $t('lists-add' ) }}</button>
                  &nbsp;
                  <a type="button" class="btn btn-primary btn-lg add-selection add" :disabled="loading" href="#/wizard/list" target="_blank">+ {{ $t('lists-create-with-wizard' ) }}</a>
                </div>
              </div>
            </div>

          <div class="row pb-2">

              <div
                class="col-lg-12 col-md-12"
                v-if="lists.length > 0"
                v-html="
                  $t('list-pagination', {
                    startItems: currentPage * 10 + 1,
                    finishItems: currentPage * 10 + lists.length,
                    totalItems: totalRecords,
                  })
                "
              ></div>
          </div>

          <div
            class="col-lg-12 no_side_pad request"
            v-if="lists && lists.length"
          >
            <div class="page-nav">
              <div class="row no_side_margin">
                <div class="col-lg-2 col-sm-4 col-4 text-right">
                  <div>
                    <a
                      :class="{ disabled: isFirstPage(), '': !isFirstPage() }"
                      v-on:click="movePrevious()"
                    >
                      <span class="nav-icon">←</span> {{ $t("previous") }}
                    </a>
                  </div>
                </div>

                <div class="col-lg-8 col-sm-4 col-4 no_side_pad text-center">
                  {{ $t("page") }} {{ currentPage + 1 }} /
                  {{ totalPages }}
                </div>

                <div class="col-lg-2 col-sm-4 col-4 no_side_pad">
                  <div>
                    <a
                      :class="{ disabled: isLastPage(), '': !isLastPage() }"
                      v-on:click="moveNext()"
                    >
                      {{ $t("next") }} <span class="nav-icon">→</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row smry-status no_side_margin" style="overflow-x: auto;">
            <table class="table table-striped list-table" style="margin-top: 0px">
              <thead class="thead-fixed">
                <tr>
                  <th class="t-head col-lg-4">{{ $t("name") }}</th>
                  <!-- <th class="t-head">{{$t('type')}}</th> -->
                  <th class="t-head text-right col-lg-2">{{ $t("total") }}</th>
                  <th class="t-head text-right col-lg-2">{{ $t("forwards") }}</th>
                  <th
                    class="t-head text-right col-lg-2"
                    style="cursor: pointer"
                    v-on:click="sortLists()"
                  >
                    {{ $t("date") }}
                  </th>
                  <th class="t-head text-right col-lg-2">{{ $t("actions") }}</th>
                </tr>
              </thead>
              <tbody v-if="this.lists.length">
                <tr v-for="(list, index) in this.lists" v-bind:key="list.id" v-bind:id="list.name">
                  <td class="table-col" v-if="list.name === 'default'">
                    default
                  </td>
                  <td
                    class="table-col"
                    :class="{ pointer: list.name !== 'default' }"
                    v-if="list.name !== 'default' && editRow !== index"
                    v-on:click="listEdit(list.name, index)"
                  >
                    {{ list.name }}
                  </td>

                  <td class="table-col" v-if="editMode && editRow === index">
                    <input
                      v-model="list.name"
                      class="drop-down"
                      value="Enter name of new list..."
                      v-on:keyup.enter="saveName(list.id)"
                      v-on:focus="$event.target.select()"
                    />
                      <span class="float-right gap-x-1">
                      <font-awesome-icon
                        v-on:click="cancelEdit()"
                        icon="times"
                        size="lg"
                        class="pointer"
                      ></font-awesome-icon>
                      <font-awesome-icon
                        v-on:click="saveName(list.id)"
                        icon="check"
                        size="lg"
                        class="pointer">
                      </font-awesome-icon>
                    </span>
                  </td>

                  <!-- <td class="table-col">{{list.type}}</td> -->
                  <td class="table-col text-right">{{ list.size }}</td>
                  <td class="table-col text-right">{{ list.forwards }}</td>
                  <td class="table-col text-right">
                    {{ list.updatedAt | formatDate }}
                  </td>
                  <td class="table-col text-right">
                    <div class="total-icon">
                      <div class="icon">
                        <a
                          :title="$t('view-list')"
                          v-bind:href="'#/mydata/lists/' + list.id"
                          v-on:click="setSubsection('list')"
                        >
                          <font-awesome-icon icon="list"/>
                        </a>
                      </div>

                      <!-- add more to wizard faster -->
                      <div class="icon">
                        <a
                          :title="$t('lists-wizard')"
                          :href="'#/wizard/list/' + list.name"
                          target="_blank"
                        >
                          <font-awesome-icon icon="magic" />
                        </a>
                      </div>

                      <!-- download -->
                      <div class="icon" v-if="list.size > 0">
                        <a
                        href="#/mydata/lists"
                          :title="$t('download-csv')"
                          v-on:click="downloadAction(list.id)"
                        ><font-awesome-icon icon="download" /></a>
                        
                      </div>
                      

                      <!-- delete -->
                      <div class="icon">
                        <a
                          href="#/mydata/lists"
                          :title="$t('delete-list')"
                          v-on:click="deleteList(list)"
                        >
                          <font-awesome-icon icon="trash"/>
                        </a>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="table-col text-center" v-if="!this.lists.length">
            {{ $t("no-lists") }}
          </div>
        </div>
        <div v-if="subsection == 'list'">
          <ListView/>
        </div>
      </div>
      <v-dialog />
    </div>
  </div>
</template>

<script>
import ListView from "./View.vue";
import ListsSettings from "./Settings.vue";

export default {
  name: "lists",
  data() {
    return {
      subsection: "index",
      lists: [],
      list_sorted_asc: true,
      pendingRequest: false,
      editRow: -1,
      editMode: false,
      loading: true,

      currentPage: 0,
      totalRecords: 0,
      totalPages: 1,
      totalRows: 0,

      user: {},
      list_entry_cost: 25,
      hasToBeSent: false,
    };
  },
  mounted() {
    var path = this.$route.path;
    //detect list id param
    if (path.indexOf("/lists/") !== -1) {
      this.subsection = "list";
    }

    this.$root.$emit("isLoading", true);
    this.getUserDetails();
    this.getLists(true);
  },
  components: {
    ListView,
    ListsSettings,
  },
  methods: {
    setSubsection: function (subsection) {
      this.subsection = subsection;
    },

    getConfigAuth: function () {
      return {
        headers: {
          Authorization:
            "Basic " +
            btoa(localStorage.configEmail + ":" + localStorage.token),
          "Content-Type": "application/json",
        },
      };
    },

    detectListId: function () {
      console.log(this.$route.params);
      if (this.$route.params.name) {
        this.subsection = "list";
      }
    },

    getUserDetails: function () {
      var url = "/api/v2/profile";
      this.$http.get(url, this.getConfigAuth()).then((results) => {
        this.user = results.data;
      });
    },

    sortLists: function () {
      this.lists = this.lists.sort(function (a, b) {
        return new Date(b.updatedAt) - new Date(a.updatedAt);
      });
      this.list_sorted_asc = !this.list_sorted_asc;
      if (!this.list_sorted_asc) {
        this.lists = this.lists.reverse();
      }
    },

    getLists: function (forced, callback) {
      if (!this.pendingRequest && forced) {
        this.pendingRequest = true;
        this.$root.$emit("isLoading", true);
        var url = "/api/v2/lists";
        //add currentPage
        url += "?page=" + this.currentPage;

        this.loading = true;
        this.$http.get(url, this.getConfigAuth()).then((results) => {
          this.loading = false;
          this.lists = results.data.results
          this.totalPages = results.data.totalPages;
          this.totalRecords = results.data.count;
          this.pendingRequest = false;
          this.$root.$emit("isLoading", false);
          if (callback) {
            callback();
          }
        });
      } else {
        //return lists;
      }
    },

    deleteList: function (list) {
      var that = this;
      this.$modal.show("dialog", {
        title: that.$t("list-delete"),
        text: that.$t("list-delete-question"),
        buttons: [
          {
            title: that.$t("accept"),
            handler: () => {
              that.pendingRequest = true;
              that.deleteListProcess(list.id, list.name);
              that.$modal.hide("dialog");
            },
          },
          {
            title: that.$t("cancel"),
          },
        ],
      });
    },

    deleteListProcess: function (id, name) {
      this.$root.$emit("isLoading", true);

      var url = "/api/v2/list/" + id + "/delete?all=true";
      this.$http.get(url, this.getConfigAuth()).then((results) => {
        this.lists = results.data;
        this.pendingRequest = false;
        this.$modal.hide("dialog");
        this.$root.$emit("isLoading", false);
        this.$toast.success(this.$t("list-deleted", { name: name }));
        this.scrollTo("homeLists");
      });
    },

    addGenericNew: function (type) {
      this.$root.$emit("isLoading", true);
      var name = type + "-" + new Date().toISOString().replace(/[-:]/g, "").split(".")[0].replace("T", "_");
      var url = "/api/v2/list";
      this.$http
        .post(
          url,
          {
            name: name,
            type: type,
          },
          this.getConfigAuth()
        )
        .then(() => {
          var that = this;
          this.getLists(true, function () {
            console.log("list created");
            that.$toast.success(that.$t("list-created", { name: name }));
            that.scrollTo(name);
          });
        });
    },

    scrollTo: function (id) {
      this.$nextTick(() => {
        var element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      });
    },

    addNew: function () {
      this.addGenericNew("people");
    },

    listEdit: function (name, index) {
      if (name !== "default") {
        this.editMode = true;
        this.editRow = index;
      }
    },

    cancelEdit: function () {
      this.editMode = false;
      this.editRow = -1;
    },

    saveName: function (list_id) {
      this.loading = true;

      var url = "/api/v2/lists";
      this.$http
        .post(
          url,
          {
            lists: this.lists
              .filter(function (l) {
                return l.id === list_id;
              })
              .map(function (l) {
                l.name = l.name.replace(/\//g, "").trim();
                return l;
              }),
          },
          this.getConfigAuth()
        )
        .then((results) => {
          this.loading = false;
          this.editMode = false;
          this.editRow = -1;
          this.lists = results.data;
        });
    },

    executeGenericAction: function (action, id) {
      this.downloaded = false;
      this.forwarded = false;
      this.loading = true;
      var url = "/api/v2/list/" + id + "/" + action;
      this.$http.get(url, this.getConfigAuth()).then(() => {
        //var data = results.data;
        this.loading = false;
        if (action === "download") {
          this.downloaded = true;
          this.$notify({
            group: "system",
            type: "success",
            title: this.$t("lists"),
            text: this.$t("list-downloaded"),
          });
        } else {
          this.forwarded = true;
        }
      });
    },

    forwardAction: function (id) {
      this.executeGenericAction("forward", id);
    },

    downloadAction: function (id) {
      this.executeGenericAction("download", id);
    },

    getCurrentPage: function() {
      return this.currentPage;
    },

    getTotalPages: function() {
      return this.totalPages;
    },

    isFirstPage: function() {
      return (
        (this.totalPages > 0 && this.currentPage === 0) ||
        (this.totalPages === 0 && this.currentPage === 0)
      );
    },

    isLastPage: function() {
      return (
        (this.totalPages > 0 && this.currentPage === this.totalPages - 1) ||
        (this.totalPages === 0 && this.currentPage === 0)
      );
    },

    moveNext: function() {
      if (this.isLastPage()) {
        this.loading = true;
      }
      if (!this.isLastPage()) {
        this.currentPage++;
        this.getLists(true);
      }
    },

    movePrevious: function() {
      if (this.isFirstPage()) {
        this.loading = true;
      }
      if (!this.isFirstPage()) {
        this.currentPage--;
        this.getLists(true);
      }
    },
  },
  watch: {
    $route(to, from) {
      console.log(`to: ${to.path}, from: ${from.path}`);
      this.section = 'lists'
      if (to.path == '/mydata/lists') {
        this.subsection = 'index'
        this.getLists(true);
      } else if (to.path == '/mydata/lists/') {
        this.detectListId();
        this.subsection = 'list'
      }
    },
  },
};
</script>
