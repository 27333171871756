<template>
<div class="col-md-3 col-lg-2 no_side_pad collapse_menu mobileViewMenu" v-bind:class="{ collapse_active: isActive }">
  <div class="nav-side-menu">
    <div class="brand"></div>
    <div class=" toggle-btn" data-toggle="collapse" data-target="#menu-content">
      <div class="bar1"></div>
      <div class="bar2"></div>
      <div class="bar3"></div>
    </div>

    <div class="menu-list">
      <ul id="menu-content" class="menu-content collapse out">
        <li v-bind:class="{ active: welcome }" v-bind:href="'#/login'" v-if="!loginChecker()">
          <a v-bind:href="'#/login'">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                d="M11.5 3.79297L16 8.29297V6.00008H17V9.29297L19.8536 12.1465L19.1465 12.8536L11.5 5.20718L3.85359 12.8536L3.14648 12.1465L11.5 3.79297ZM5.00004 13.0001H6.00004V20.0001H10V16.0001H14V20.0001H17V13.0001H18V21.0001H13V17.0001H11V21.0001H5.00004V13.0001Z" />
            </svg><span>{{ $t('menu-login' ) }}</span>
          </a>
        </li>

        <li v-bind:class="{ active: dashboard }" v-bind:href="'#/login'" v-if="!loginChecker()">
          <a v-bind:href="'#/login'">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                d="M11.5 3.79297L16 8.29297V6.00008H17V9.29297L19.8536 12.1465L19.1465 12.8536L11.5 5.20718L3.85359 12.8536L3.14648 12.1465L11.5 3.79297ZM5.00004 13.0001H6.00004V20.0001H10V16.0001H14V20.0001H17V13.0001H18V21.0001H13V17.0001H11V21.0001H5.00004V13.0001Z" />
            </svg><span>{{ $t('menu-login' ) }}</span>
          </a>
        </li>

        <li v-bind:class="{ active: welcome }" v-on:click="redirect('/welcome')" v-if="loginChecker()" id="welcome">
          <a v-bind:href="'#/welcome'" :title="$t('menu-panel')">
            <font-awesome-icon icon="bullseye" size="lg" v-bind:class="{ 'blue-link': welcome }" /><span v-bind:class="{ 'blue-link': welcome }">{{ $t('menu-welcome' ) }}</span>
          </a>
        </li>

        <li v-bind:class="{ active: dashboard }" v-on:click="redirect('/dashboard')" v-if="loginChecker()" id="dashboard">
          <a v-bind:href="'#/dashboard'" :title="$t('menu-panel')">
            <font-awesome-icon icon="home" size="lg" v-bind:class="{ 'blue-link': dashboard }" /><span v-bind:class="{ 'blue-link': dashboard }">{{ $t('menu-panel' ) }}</span>
          </a>
        </li>


        <li data-toggle="collapse" data-target="#mydata" class="collapsed " v-bind:class="{ active: mydata }" v-on:click="redirect('/mydata/bulks')" v-if="loginChecker()">
          <a href="#/mydata/bulks" :title="$t('menu-mydata')">
            <font-awesome-icon icon="database" size="lg" v-bind:class="{ 'blue-link': mydata }" /><span v-bind:class="{ 'blue-link': mydata }">{{ $t('menu-mydata' ) }}</span>
          </a>
        </li>

        <li data-toggle="collapse" data-target="#service" class="collapsed " v-bind:class="{ active: wizard }" v-on:click="redirect('/wizard/bulk')" v-if="loginChecker()">
          <a v-bind:href="'#/wizard/file'" :title="$t('menu-wizard')">
            <font-awesome-icon icon="magic" size="lg" v-bind:class="{ 'blue-link': wizard }" /><span v-bind:class="{ 'blue-link': wizard }">{{ $t('menu-wizard' ) }}</span>
          </a>
        </li>

        <li data-toggle="collapse" data-target="#products" class="collapsed " v-bind:class="{ active: purchase }" v-on:click="redirect('/payments')" v-if="loginChecker()">
          <a v-bind:href="'#/payments'" :title="$t('menu-purchase')">
            <font-awesome-icon icon="shopping-cart" size="lg" v-bind:class="{ 'blue-link': purchase }" /><span v-bind:class="{ 'blue-link': purchase }">{{ $t('menu-purchase' ) }}</span>
          </a>
        </li>


        <li v-bind:class="{ active: tools }" v-on:click="redirect('/tools')" v-if="loginChecker()">
          <a v-bind:href="'#/tools'" :title="$t('menu-tools')">
            <font-awesome-icon icon="lightbulb" size="lg" v-bind:class="{ 'blue-link': tools }" /><span v-bind:class="{ 'blue-link': tools }">{{ $t('menu-tools' ) }}</span>
          </a>
        </li>


       <li v-bind:class="{ active: settings }" v-on:click="redirect('/settings')" v-if="loginChecker()">
          <a v-bind:href="'#/settings'" :title="$t('menu-setting')">
            <font-awesome-icon icon="cog" size="lg" v-bind:class="{ 'blue-link': settings }" /><span v-bind:class="{ 'blue-link': settings }">{{ $t('menu-setting' ) }}</span>
          </a>
        </li>


        <li v-bind:class="{ active: contact }" v-on:click="redirect('/contact')" v-if="loginChecker()">
          <a v-bind:href="'#/contact'" :title="$t('menu-help')">
            <font-awesome-icon icon="comments" size="lg" v-bind:class="{ 'blue-link': contact }" /><span v-bind:class="{ 'blue-link': contact }">{{ $t('menu-help' ) }}</span>
          </a>
        </li>

        <li v-bind:class="{ active: changes }" v-on:click="redirect('/changes')" v-if="loginChecker()">
          <a v-bind:href="'#/changes'" :title="$t('menu-changes')">
            <font-awesome-icon icon="exchange-alt" size="lg" v-bind:class="{ 'blue-link': changes }" /><span v-bind:class="{ 'blue-link': changes }">{{ $t('menu-changes' ) }}</span>
          </a>
        </li>

        <li  v-on:click="logOut()" v-if="loginChecker()">
          <a v-bind:href="'#/logout'" :title="$t('menu-logout')">
            <font-awesome-icon icon="sign-out-alt" size="lg"/><span>{{ $t('menu-logout' ) }}</span>
          </a>
        </li>

      </ul>
    </div>



  </div>


</div>
</template>

<script>
export default {
  name: 'Sidebar',
  data() {
    return {
      isActive: false,
      isActiveIcon: '<',
      welcome: false,
      dashboard: false,
      mydata: false,
      purchase: false,
      wizard: false,
      bulks: false,
      tools: false,
      contact: false,
      settings: false,
      changes: false
    };
  },
  mounted() {
    if (this.$route.path.includes("dashboard")) {
      this.dashboard = true;
    } else if (this.$route.path.includes("mydata") || this.$route.path.includes("bulks")) {
      this.mydata = true;
    } else if (this.$route.path.includes("welcome")) {
      this.welcome = true;
    } else if (this.$route.path.includes("wizard")) {
      this.wizard = true;
    } else if (this.$route.path.includes("tools")) {
      this.tools = true;
    } else if (this.$route.path.includes("contact")) {
      this.contact = true;
    } else if (this.$route.path.includes("settings")) {
      this.settings = true;
    } else if (this.$route.path.includes("changes")) {
      this.changes = true;
    }

    this.$root.$on('isActived', (result) => {
      this.isActive = result;
      localStorage.isActive = result;
      if (this.isActive)
        this.isActiveIcon = '>'
      else
        this.isActiveIcon = '<'
    })

  },
  methods: {
    redirect(route) {
      this.$router.push(route);
    },


    loginChecker() {
      return localStorage.token && localStorage.token != this.$defaultKey ? true : false;
    },

    logOut: function() {
      localStorage.token = '';
      this.$router.push("/login");
      location.reload();
    },

  }
}
</script>
