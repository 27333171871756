<template>
<div>
  <div v-if="language === 'es'">
    He visto que quieres <strong>enviar campañas de SMS</strong> ;)<br>
    ¿Sabes que con uProc <strong>evitas los problemas de entrega</strong> si <strong>verificas los móviles</strong> previamente?
    <br><br>
    Consíguelo en tres sencillos pasos:
    <ul>
      <li><strong><a href="/#/wizard/file">Sube</a></strong> tu fichero desde el asistente.</li>
      <li><strong>Elije</strong> la herramienta <strong>"Comprobar si móvil existe"</strong> y procesa tu fichero.</li>
      <li><strong>Recibe</strong> los resultados por correo.</li>
    </ul>
    Recuerda que también puedes comprobar tus móviles desde
    <strong><a href="https://uproc.io/blog/es/how-to-check-if-mobile-exists#sheets" target="_blank">Google Sheets</a></strong>,
    <strong><a href="https://uproc.io/blog/es/how-to-check-if-mobile-exists#n8n" target="_blank">n8n</a></strong>,
    <strong><a href="https://uproc.io/blog/es/how-to-check-if-mobile-exists#make" target="_blank">Make</a></strong>,
    <strong><a href="https://uproc.io/blog/es/how-to-check-if-mobile-exists#zapier" target="_blank">Zapier</a></strong> o
    <strong><a href="https://uproc.io/blog/es/how-to-check-if-mobile-exists#api" target="_blank">API</a></strong>.

    <br>
    Elije el método que mejor se adapta a tus necesidades.
    <!-- <br><br>
    ¿Quieres ver un ejemplo rápido? Revisa este videotutorial para ver la herramienta en acción utilizando Google Sheets:
    <div class="video-responsive">
      <iframe width="100%" height="360" src="https://www.youtube.com/embed/gLgsnolLGfA?VQ=HD720" frameborder="0" allowfullscreen="allowfullscreen"></iframe>
    </div> -->
  </div>
  <div v-else>
    I see you want to <strong>send SMS campaigns</strong> ;)<br>
    Did you know that with uProc you can <strong>avoid delivery issues</strong> if you <strong>verify the mobile numbers</strong> beforehand?
    <br><br>
    Achieve it in three simple steps:
    <ul>
      <li><strong><a href="/#/wizard/file">Upload</a></strong> your file from the wizard.</li>
      <li><strong>Choose</strong> the tool <strong>"Check if mobile exists"</strong> and process your file.</li>
      <li><strong>Receive</strong> the results by email.</li>
    </ul>
    Remember that you can also check your mobile numbers from
    <strong><a href="https://uproc.io/blog/en/how-to-check-if-mobile-exists#sheets" target="_blank">Google Sheets</a></strong>,
    <strong><a href="https://uproc.io/blog/en/how-to-check-if-mobile-exists#n8n" target="_blank">n8n</a></strong>,
    <strong><a href="https://uproc.io/blog/en/how-to-check-if-mobile-exists#make" target="_blank">Make</a></strong>,
    <strong><a href="https://uproc.io/blog/en/how-to-check-if-mobile-exists#zapier" target="_blank">Zapier</a></strong> or
    <strong><a href="https://uproc.io/blog/en/how-to-check-if-mobile-exists#api" target="_blank">API</a></strong>.

    <br>
    Choose the method that best suits your needs.
    <!-- <br><br>
    ¿Quieres ver un ejemplo rápido? Revisa este videotutorial para ver la herramienta en acción utilizando Google Sheets:
    <div class="video-responsive">
      <iframe width="100%" height="360" src="https://www.youtube.com/embed/gLgsnolLGfA?VQ=HD720" frameborder="0" allowfullscreen="allowfullscreen"></iframe>
    </div> -->
  </div>
</div>
</template>

<script>
export default {
  name: 'WelcomeSms',
  data() {
    return {
      language: this.$i18n.locale
    };
  },
  created() {

  },
  mounted() {}
}
</script>
