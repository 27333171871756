<template>
<div class="row">
    <div class="col-lg-12">
        <div class="select-file">
            <p class="text-left pb-4">{{ $t('wizard-authorized-ip') }}</p>
            
            <div class="form-group row">
                <div for="host" class="col-sm-2 text-left">{{ $t('mongo-host') }}</div>
                <div class="col-sm-10">
                    <input type="text" id="host" v-model="details.host" :placeholder="$t('mongo-host-placeholder')" class="form-control">
                </div>
            </div>

            <div class="form-group row mt-2">
                <div for="port" class="col-sm-2 text-left">{{ $t('mongo-port') }}</div>
                <div class="col-sm-10">
                    <input type="text" id="port" v-model="details.port" :placeholder="$t('mongo-port-placeholder')" class="form-control">
                </div>
            </div>
            
            <div class="form-group row mt-2">
                <div for="username" class="col-sm-2 text-left">{{ $t('mongo-username') }}</div>
                <div class="col-sm-10">
                    <input type="text" id="username" v-model="details.username" :placeholder="$t('mongo-username-placeholder')" class="form-control">
                </div>
            </div>
            
            <div class="form-group row mt-2">
                <div for="password" class="col-sm-2 text-left">{{ $t('mongo-password') }}</div>
                <div class="col-sm-10">
                    <input type="password" id="password" v-model="details.password" :placeholder="$t('mongo-password-placeholder')" class="form-control">
                </div>
            </div>

            <div class="form-group row mt-2">
                <div for="database" class="col-sm-2 text-left">{{ $t('mongo-database') }}</div>
                <div class="col-sm-10">
                    <input type="text" id="database" v-model="details.database" :placeholder="$t('mongo-database-placeholder')" class="form-control">
                </div>
            </div>

            <div class="form-group row mt-2">
                <div for="collection" class="col-sm-2 text-left">{{ $t('mongo-collection') }}</div>
                <div class="col-sm-10">
                    <input type="text" id="collection" v-model="details.collection" :placeholder="$t('mongo-collection-placeholder')" class="form-control">
                </div>
            </div>
            
            <div class="form-group row mt-2">
                <div for="fields" class="col-sm-2 text-left">{{ $t('mongo-fields') }}</div>
                <div class="col-sm-10">
                    <input type="text" id="fields" v-model="details.fields" :placeholder="$t('mongo-fields-placeholder')" class="form-control">
                </div>
            </div>            
        </div>
        <ChangeSource currentType="mongo" v-on:change-source="setSource" />
    </div>
</div>
</template>
<script>
import ChangeSource from '@/components/Wizards/sources/ChangeSource.vue';
 export default {
    name: 'source-url',
    data() {
        return {
            details: {
                host: '',
                port: '27017',
                database: '',
                collection: '',
                username: '',
                password: ''
            }
        };
    },
    components: {
        ChangeSource
    },
    methods: {
        setSource(source) {
            this.$emit('change-source', source);
        },
        analyze() {
            this.$emit('upload', this.details);
        }
    }
};
</script>
