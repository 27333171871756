<template>
  <ol class="list-items text-left">
    <li><strong>PERSON.ID</strong>: {{ $t('list-person-id-description' ) }}</li>
    <li><strong>PERSON.ADDED</strong>: {{ $t('list-person-added-description' ) }}</li>
    <li><strong>PERSON.PROFILE</strong>: {{ $t('list-person-profile-description' ) }}</li>
    <li><strong>PERSON.NAME</strong>: {{ $t('list-person-firstname-description' ) }}</li>
    <li><strong>PERSON.SURNAME</strong>: {{ $t('list-person-lastname-description' ) }}</li>
    <li><strong>PERSON.GENDER</strong>: {{ $t('list-person-gender-description' ) }}</li>
    <li><strong>PERSON.PERSONAL_EMAIL</strong>: {{ $t('list-person-personal_email-description' ) }}</li>
    <li><strong>PERSON.PERSONAL_PHONE</strong>: {{ $t('list-person-personal_phone-description' ) }}</li>
    <li><strong>PERSON.FACEBOOK</strong>: {{ $t('list-person-personal_facebook-description' ) }}</li>
    <li><strong>PERSON.GITHUB</strong>: {{ $t('list-person-personal_github-description' ) }}</li>
    <li><strong>PERSON.TWITTER</strong>: {{ $t('list-person-personal_twitter-description' ) }}</li>
    <li><strong>PERSON.EMAIL</strong>: {{ $t('list-person-email-description' ) }}</li>
    <li><strong>PERSON.EMAIL_CONFIDENCE</strong>: {{ $t('list-person-email_confidence-description' ) }}</li>
    <li><strong>PERSON.EMAIL_SCORE</strong>: {{ $t('list-person-email_score-description' ) }}</li>
    <li><strong>PERSON.ROLE</strong>: {{ $t('list-person-role-description' ) }}</li>
    <li><strong>PERSON.AREA</strong>: {{ $t('list-person-area-description' ) }}</li>
    <li><strong>PERSON.SENIORITY</strong>: {{ $t('list-person-seniority-description' ) }}</li>
    <li><strong>PERSON.IS_DECISION_MAKER</strong>: {{ $t('list-person-is_decision_maker-description' ) }}</li>
    <li><strong>PERSON.IS_C_LEVEL</strong>: {{ $t('list-person-is_c_level-description' ) }}</li>
    <li><strong>PERSON.ANNUAL_SALARY</strong>: {{ $t('list-person-annual_salary-description' ) }}</li>
    <li><strong>COMPANY_GENERAL.NAME</strong>: {{ $t('list-person-name-description' ) }}</li>
    <li><strong>COMPANY_GENERAL.DESCRIPTION</strong>: {{ $t('list-person-description-description' ) }}</li>
    <li><strong>COMPANY_CONTACT.PHONE</strong>: {{ $t('list-person-phone-description' ) }}</li>
    <li><strong>COMPANY_CONTACT.EMAIL</strong>: {{ $t('list-person-generic_email-description' ) }}</li>
    <li><strong>COMPANY_CONTACT.WEBSITE</strong>: {{ $t('list-person-website-description' ) }}</li>
    <li><strong>COMPANY_CONTACT.DOMAIN</strong>: {{ $t('list-person-domain-description' ) }}</li>
    <li><strong>COMPANY_CONTACT.ADDRESS</strong>: {{ $t('list-person-address-description' ) }}</li>
    <li><strong>COMPANY_CONTACT.CITY</strong>: {{ $t('list-person-city-description' ) }}</li>
    <li><strong>COMPANY_CONTACT.ZIPCODE</strong>: {{ $t('list-person-zipcode-description' ) }}</li>
    <li><strong>COMPANY_CONTACT.COUNTY</strong>: {{ $t('list-person-county-description' ) }}</li>
    <li><strong>COMPANY_CONTACT.STATE</strong>: {{ $t('list-person-state-description' ) }}</li>
    <li><strong>COMPANY_CONTACT.COUNTRY</strong>: {{ $t('list-person-country-description' ) }}</li>
    <li><strong>COMPANY_CONTACT.COUNTRY_CODE</strong>: {{ $t('list-person-country_code-description' ) }}</li>
    <li><strong>COMPANY_CONTACT.LATITUDE</strong>: {{ $t('list-person-latitude-description' ) }}</li>
    <li><strong>COMPANY_CONTACT.LONGITUDE</strong>: {{ $t('list-person-longitude-description' ) }}</li>
    <li><strong>COMPANY_CONTACT.LANGUAGE_CODE</strong>: {{ $t('list-person-language_code-description' ) }}</li>
    <li><strong>COMPANY_CONTACT.LANGUAGE_NAME</strong>: {{ $t('list-person-language_name-description' ) }}</li>
    <li><strong>COMPANY_CONTACT.TIMEZONE</strong>: {{ $t('list-person-timezone-description' ) }}</li>
    <li><strong>COMPANY_CONTACT.OFFSET</strong>: {{ $t('list-person-offset-description' ) }}</li>
    <li><strong>COMPANY_CONTACT.ZONE</strong>: {{ $t('list-person-zone-description' ) }}</li>
    <li><strong>COMPANY_FINANCIAL.TYPE</strong>: {{ $t('list-person-type-description' ) }}</li>
    <li><strong>COMPANY_FINANCIAL.YEAR_FOUNDED</strong>: {{ $t('list-person-founded-description' ) }}</li>
    <li><strong>COMPANY_FINANCIAL.INDUSTRY</strong>: {{ $t('list-person-industry-description' ) }}</li>
    <li><strong>COMPANY_FINANCIAL.REVENUE</strong>: {{ $t('list-person-revenue-description' ) }}</li>
    <li><strong>COMPANY_FINANCIAL.SIZE</strong>: {{ $t('list-person-size-description' ) }}</li>
    <li><strong>COMPANY_WEBSITE.QUERY_DATE</strong>: {{ $t('list-person-query_date-description' ) }}</li>
    <li><strong>COMPANY_WEBSITE.RANK</strong>: {{ $t('list-person-rank-description' ) }}</li>
    <li><strong>COMPANY_WEBSITE.LAST_WEEK_VISITS</strong>: {{ $t('list-person-last_week_visits-description' ) }}</li>
    <li><strong>COMPANY_WEBSITE.LAST_MONTH_VISITS</strong>: {{ $t('list-person-last_month_visits-description' ) }}</li>
    <li><strong>COMPANY_WEBSITE.LAST_QUARTER_VISITS</strong>: {{ $t('list-person-last_quarter_visits-description' ) }}</li>
    <li><strong>COMPANY_BLOG.LAST_ENTRY_DATE</strong>: {{ $t('list-person-entry_date-description' ) }}</li>
    <li><strong>COMPANY_BLOG.LAST_ENTRY_TITLE</strong>: {{ $t('list-person-entry_title-description' ) }}</li>
    <li><strong>COMPANY_BLOG.LAST_ENTRY_AUTHOR</strong>: {{ $t('list-person-entry_author-description' ) }}</li>
    <li><strong>COMPANY_BLOG.LAST_ENTRY_LINK</strong>: {{ $t('list-person-entry_link-description' ) }}</li>
    <li><strong>COMPANY_SOCIAL.DISCOURSE</strong>: {{ $t('list-person-discord-description' ) }}</li>
    <li><strong>COMPANY_SOCIAL.FACEBOOK</strong>: {{ $t('list-person-facebook-description' ) }}</li>
    <li><strong>COMPANY_SOCIAL.GITHUB</strong>: {{ $t('list-person-github-description' ) }}</li>
    <li><strong>COMPANY_SOCIAL.INSTAGRAM</strong>: {{ $t('list-person-instagram-description' ) }}</li>
    <li><strong>COMPANY_SOCIAL.LINKEDIN</strong>: {{ $t('list-person-linkedin-description' ) }}</li>
    <li><strong>COMPANY_SOCIAL.PINTEREST</strong>: {{ $t('list-person-pinterest-description' ) }}</li>
    <li><strong>COMPANY_SOCIAL.SLACK</strong>: {{ $t('list-person-slack-description' ) }}</li>
    <li><strong>COMPANY_SOCIAL.SLIDESHARE</strong>: {{ $t('list-person-slideshare-description' ) }}</li>
    <li><strong>COMPANY_SOCIAL.TELEGRAM</strong>: {{ $t('list-person-telegram-description' ) }}</li>
    <li><strong>COMPANY_SOCIAL.TIKTOK</strong>: {{ $t('list-person-tiktok-description' ) }}</li>
    <li><strong>COMPANY_SOCIAL.TWITCH</strong>: {{ $t('list-person-twitch-description' ) }}</li>
    <li><strong>COMPANY_SOCIAL.TWITTER</strong>: {{ $t('list-person-twitter-description' ) }}</li>
    <li><strong>COMPANY_SOCIAL.YOUTUBE</strong>: {{ $t('list-person-youtube-description' ) }}</li>
    <li><strong>COMPANY_TECHNOLOGIES.SPEND</strong>: {{ $t('list-person-spend-description' ) }}</li>
    <li><strong>COMPANY_TECHNOLOGIES.HAS_SHOP</strong>: {{ $t('list-person-has_shop-description' ) }}</li>
    <li><strong>COMPANY_TECHNOLOGIES.RESULT</strong>: {{ $t('list-person-result-description' ) }}</li>
    <li><strong>LINKEDIN.SEARCH</strong>: {{ $t('list-person-search-description' ) }}</li>
  </ol>
</template>
<script>
export default {
  props: ['list_type_person']
};
</script>
