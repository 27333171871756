<template>
<div>
  <div v-if="language === 'es'">
    He visto que quieres <strong>mejorar tu embudo de ventas</strong> ;)<br>
    ¿Sabes que con uProc puedes obtener <strong>muchos contactos</strong> para tu equipo de ventas en un momento?
    <br><br>
    Recuerda que puedes lograrlo siguiendo uno de estos pasos:
    <ul>
      <li><strong><a href="/#/wizard/file">Sube</a></strong> un fichero con dominios y extrae los contactos de las empresas.</li>
      <li><strong><a href="/#/wizard/list">Utiliza</a></strong> nuestra base de datos para generar tus listas de contactos.</li>
      <li><strong><a href="/#/mydata/lists">Crea</a></strong> una lista y utiliza <strong><a
              href="https://chrome.google.com/webstore/detail/uproc-for-linkedin/odfhegllgcagejmjbanonlofmllejfea?hl=en&authuser=0"
              target="_blank"
              >uProc for LinkedIn</a></strong> para escrapear contactos desde <strong
            ><a
              href="https://www.linkedin.com/sales/search/people"
              target="_blank"
              >Sales Navigator</a
            ></strong> o <strong><a
              href="https://www.linkedin.com/search/results/people"
              target="_blank"
              >LinkedIn</a></strong>.</li>
    </ul>
    <br><br>
    <!-- <br>
    Dudas? <strong><a href="https://uproc.io/blog/es/boost-your-sales-with-uproc-for-linkedin" target="_blank">Sigue este tutorial</a></strong> para entender como extraer contactos desde LinkedIn.
    <br><br>
    <div class="row">
      <div class="col-lg-4">
      ¿Quieres ver un ejemplo rápido? Comprueba el video para ver el bot en acción en LinkedIn:
      </div>
      <div class="col-lg-8">
        <div class="video-responsive">
        <iframe width="100%" height="360" src="https://www.youtube.com/embed/VGv7BVNM5rg?VQ=HD720" frameborder="0" allowfullscreen="allowfullscreen"></iframe>
        </div>
      </div>
    </div> -->
  </div>
  <div v-else>
    I see that you want to <strong>improve your sales funnel</strong> ;)<br>
    Did you know that with uProc you can get <strong>many contacts</strong> for your sales team in no time?
    <br><br>
    Remember that you can achieve it by following one of these steps:
    <ul>
      <li><strong><a href="/#/wizard/file">Upload</a></strong> a file with domains and extract the contacts of the companies.</li>
      <li><strong><a href="/#/wizard/list">Use</a></strong> our database to generate your contact lists.</li>
      <li><strong><a href="/#/mydata/lists">Create</a></strong> a list and use <strong><a
          href="https://chrome.google.com/webstore/detail/uproc-for-linkedin/odfhegllgcagejmjbanonlofmllejfea?hl=en&authuser=0"
          target="_blank"
          >uProc for LinkedIn</a></strong> to scrape contacts from <strong
        ><a
          href="https://www.linkedin.com/sales/search/people"
          target="_blank"
          >Sales Navigator</a
        ></strong> or <strong><a
          href="https://www.linkedin.com/search/results/people"
          target="_blank"
          >LinkedIn</a></strong>.</li>
    </ul>
    <br><br>
    <!-- <br>
    Dudas? <strong><a href="https://uproc.io/blog/es/boost-your-sales-with-uproc-for-linkedin" target="_blank">Sigue este tutorial</a></strong> para entender como extraer contactos desde LinkedIn.
    <br><br>
    <div class="row">
      <div class="col-lg-4">
      ¿Quieres ver un ejemplo rápido? Comprueba el video para ver el bot en acción en LinkedIn:
      </div>
      <div class="col-lg-8">
        <div class="video-responsive">
        <iframe width="100%" height="360" src="https://www.youtube.com/embed/VGv7BVNM5rg?VQ=HD720" frameborder="0" allowfullscreen="allowfullscreen"></iframe>
        </div>
      </div>
    </div> -->
  </div>
</div>
</template>

<script>
export default {
  name: 'WelcomeSales',
  data() {
    return {
      language: this.$i18n.locale
    };
  },
  created() {

  },
  mounted() {}
}
</script>
