<template>
<div class="row">
    <div class="col-lg-12">
        <div class="select-file">
            <p class="text-left pb-4">{{ $t('wizard-authorized-ip') }}</p>

            <div class="form-group row">
                <div class="col-sm-2 text-left">{{ $t('s3-bucket') }}</div>
                <div class="col-sm-10">
                    <input type="text" v-model="details.bucket" :placeholder="$t('s3-bucket-placeholder')" class="form-control">
                </div>
            </div>

            <div class="form-group row mt-2">
                <div class="col-sm-2 text-left">{{ $t('s3-region') }}</div>
                <div class="col-sm-10">
                    <input type="text" v-model="details.region" :placeholder="$t('s3-region-placeholder')" class="form-control">
                </div>
            </div>
            
            <div class="form-group row mt-2">
                <div class="col-sm-2 text-left">{{ $t('s3-file-path') }}</div>
                <div class="col-sm-10">
                    <input type="text" v-model="details.filePath" :placeholder="$t('s3-file-path-placeholder')" class="form-control">
                </div>
            </div>
            <div class="form-group row mt-2">
                <div class="col-sm-2 text-left">{{ $t('s3-access-key') }}</div>
                <div class="col-sm-10">
                    <input type="text" v-model="details.accessKey" :placeholder="$t('s3-access-key-placeholder')" class="form-control">
                </div>
            </div>
            <div class="form-group row mt-2">
                <div class="col-sm-2 text-left">{{ $t('s3-secret-key') }}</div>
                <div class="col-sm-10">
                    <input type="password" v-model="details.secretKey" :placeholder="$t('s3-secret-key-placeholder')" class="form-control">
                </div>
            </div>
            
            
        </div>
        <ChangeSource currentType="s3" v-on:change-source="setSource" />
    </div>
</div>
</template>
<script>
import ChangeSource from '@/components/Wizards/sources/ChangeSource.vue';

 export default {
    name: 'source-url',
    data() {
        return {
            details: {
                bucket: '',
                region: '',
                filePath: '',
                accessKey: '',
                secretKey: ''
            }
        };
    },
    components: {
        ChangeSource
    },
    methods: {
        setSource(source) {
            this.$emit('change-source', source);
        },
        analyze() {
            this.$emit('upload', this.details);
        }
    }
};
</script>
