<template>
<div class="row">
    <div class="col-12 text-left">
        <small class="text-muted">
            {{ $t("table-input-chosen", {name: $t("source-" + currentType)}) }}
            <div class="d-flex flex-wrap">
                <a v-for="source in sourceNames.filter(source => source.key !== currentType)" :key="source.key" href="#/wizard/file" v-on:click="setSource(source.key)" class="gap-x-2 mb-2">
                    {{$t("source-" + source.key)}}
                </a>
            </div>
        </small>
    </div>
</div>
    
</template>
<script>
export default {
    props: {
        currentType: String,
    },
    data() {
        return {
            sourceNames: [
                { key: "upload", name: "File" },
                { key: "mongo", name: "MongoDB" },
                { key: "mysql", name: "MySQL" },
                { key: "postgres", name: "MySQL" },
                { key: "sftp", name: "SFTP" },
                { key: "s3", name: "S3" },
                { key: "url", name: "URL" },
            ],
        };
    },

    methods: {
        setSource(source) {
            console.log("source", source);
            this.$emit("change-source", source);
        },
    },
}
</script>