<template>
<div class="row">
    <div class="col-lg-12">
        <p class="text-left pt-4 pb-4">{{ $t('wizard-authorized-ip') }}</p>

        <div class="form-group row">
            <div :for="'host'" class="col-sm-2 text-left">{{ $t('api-url') }}</div>
            <div class="col-sm-10">
                <input type="text" id="host" v-model="details.url" :placeholder="$t('api-url-placeholder')" class="form-control">
            </div>
        </div>

        <div class="form-group row mt-2">
            <div :for="'port'" class="col-sm-2 text-left">{{ $t('api-credentials') }}</div>
            <div class="col-sm-10">
                <input type="text" id="port" v-model="details.credentials" :placeholder="$t('api-credentials-placeholder')" class="form-control">
            </div>
        </div>

    </div>
</div>
</template>
<script>
 export default {
    name: 'source-api',
    data() {
        return {
            details: {
                url: '',
                credentials: ''
                
            }
        };
    },
    methods: {
        checkInput() {
            // this.$emit('input', this.details);
        }
    }
};
</script>
