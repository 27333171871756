<template>
<div class="row">
    <div class="col-lg-12">
        <div class="text-center pt-4 pb-4">
            <div class="select-file">
                <div class="icon">
                    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M10 6H27.4142L38 16.5858V42H10V6ZM12 8V40H36V18H26V8H12ZM28 9.41421L34.5858 16H28V9.41421Z"
                        fill="#0300e2" />
                    </svg>
                </div>
                <input type="file" accept=".csv,.xls,.xlsx,.zip,.tgz,.gz" id="file" ref="file"
                    class="custom-file-input upload-data pointer" lang="es" v-on:change="uploadFile()">
                <h6>{{ $t('wizard-file-computer') }}</h6>
                </div>
                <div class="small">
                <p v-html="$t('wizard-file-format')"></p>
            </div>
        </div>

    </div>
</div>
</template>
<script>
 export default {
    name: 'source-upload',
    data() {
        return {
            details: {
                file: ''
            }
        };
    },
    methods: {
        checkInput() {
            // this.$emit('input', this.details);
        }
    }
};
</script>
