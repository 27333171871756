<template>
<div>

  <Header />

  <div class="row no_side_margin content">

    <Sidebar />

    <div class="col-md-9 col-lg-10 no_side_pad" v-bind:class="{ collapse_active_footer: isActive }">

      <button @click="menuCollapse" class="collapse-btn">{{isActiveIcon}}</button>




      <!-- wizard content start start -->

      <div class="">
        <div class="total-tools">

          <div class="row no_side_margin">

            <div class="col-lg-5 col-sm-5 col-md-5 no_side_pad">
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item "><a v-bind:href="'#/tools/'" v-on:click="resetProfile()">{{ $t('tools' ) }}</a></li>
                  <li class="breadcrumb-item " v-if="hasProfile()"><a>{{$t('search-profile-'+this.profile)}}</a></li>

                </ol>
              </nav>
            </div>

            <div class="tools-group col-lg-7 col-md-7 col-sm-7 no_side_pad" v-if="hasProfile()">
              <div class="tools-apply">
                <button type="button" class="d-wrk" v-on:click="resetProfile()">{{ $t('search-profile-workon' ) }} {{$t('search-profile-'+this.profile)}}</button>
              </div>
            </div>

            <div class="col-lg-12 pb-4 text-center" v-if="this.isTechnicalProfile()">
                <div>{{$t('tools-group')}}</div>
                <div class="btn-group" role="group">
                  <button class="btn" v-bind:class="{ 'btn-primary': mode === 'groups' }" v-on:click="toggleViewMode('groups')">{{$t('tools-group-category')}}</button>
                  <button class="btn" v-bind:class="{ 'btn-primary': mode === 'fields' }" v-on:click="toggleViewMode('fields')">{{$t('tools-group-field')}}</button>
                </div>
            </div>
          </div>

            <div class="row no_side_margin text-center" v-if="!loading && !hasProfile()">
            <h4>{{ $t('work-on' ) }}</h4>

            <div class="row no_side_margin row-eq-height justify-content-center">
              <div class="col-lg-4 tool-wrap">
              <div class="tools-item" v-on:click="setProfile('sales')">
                <div class="icon">
                <font-awesome-icon icon="chart-line" size="3x" color="#0300e2" />
                </div>
                <h3>{{$t('search-profile-sales')}}</h3>
                <p>{{$t('search-profile-sales-description')}}</p>
              </div>
              </div>

              <div class="col-lg-4 tool-wrap">
              <div class="tools-item" v-on:click="setProfile('marketing')">
                <div class="icon">
                <font-awesome-icon icon="chart-pie" size="3x" color="#0300e2" />
                </div>
                <h3>{{$t('search-profile-marketing')}}</h3>
                <p>{{$t('search-profile-marketing-description')}}</p>
              </div>
              </div>

              <div class="col-lg-4 tool-wrap">
              <div class="tools-item" v-on:click="setProfile('marketplaces')">
                <div class="icon">
                <font-awesome-icon icon="tags" size="3x" color="#0300e2" />
                </div>
                <h3>{{$t('search-profile-marketplaces')}}</h3>
                <p>{{$t('search-profile-marketplaces-description')}}</p>
              </div>
              </div>

              <div class="col-lg-4 tool-wrap">
              <div class="tools-item" v-on:click="setProfile('technical')">
                <div class="icon">
                <font-awesome-icon icon="file-code" size="3x" color="#0300e2" />
                </div>
                <h3>{{$t('search-profile-technical')}}</h3>
                <p>{{$t('search-profile-technical-description')}}</p>
              </div>
              </div>
            </div>
            </div>

          <div class="row no_side_margin sales-tools" v-if="hasProfile() && this.profileSelectedProcessors.length && !this.isTechnicalProfile()">
            <div class="col-lg-3 wrapper no_side_pad" v-for="(processor) in this.profileSelectedProcessors" v-bind:key="processor.key">

              <a v-bind:href="'#/tools/processor/' + processor.type + '/' + processor.module + '/' + processor.name">
                <div class="all-tool-item">
                  <IconGroup :group="processor.groups[0]" />
                  <span>{{ $t("type-" + processor.type + "-verb" ) }}</span>
                  <h6><strong>{{processor.description}}</strong></h6>
                </div>
              </a>
            </div>
          </div>


          <div class="row no_side_margin sales-tools">

            <div class="col-lg-12 btn btn-default btn-primary" v-if="hasProfile() && !this.isTechnicalProfile()" v-on:click="setProfile('technical')">{{$t('show-all-tools')}}</div>
          </div>


          <div class="row no_side_margin" v-if="this.isTechnicalProfile()">

            <div class="col-lg-12 no_side_pad search-by-field" v-if="this.hasView()">
              <div class="">
                <div class="search-caption">
                  {{$t('search-keyword')}}
                </div>
                <div class="search-field">
                  <input type="text" class="form-control" id="textSearch" v-model="query" @focus="$event.target.select()" @click="$event.target.select()" v-on:change="onQueryChanged()" v-on:keydown="setQueryTextManual()"
                    v-on:keyup.enter="setQueryTextManualEnter(query)">
                  <div class="icon">
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M9.16675 3.75033C6.17521 3.75033 3.75008 6.17545 3.75008 9.16699C3.75008 12.1585 6.17521 14.5837 9.16675 14.5837C12.1583 14.5837 14.5834 12.1585 14.5834 9.16699C14.5834 6.17545 12.1583 3.75033 9.16675 3.75033ZM2.91675 9.16699C2.91675 5.71521 5.71497 2.91699 9.16675 2.91699C12.6185 2.91699 15.4167 5.71521 15.4167 9.16699C15.4167 12.6188 12.6185 15.417 9.16675 15.417C5.71497 15.417 2.91675 12.6188 2.91675 9.16699Z"
                        fill="#0300e2" />
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M16.3721 16.9616L13.0388 13.6283L13.628 13.039L16.9614 16.3724L16.3721 16.9616Z" fill="#0300e2" />
                    </svg>

                  </div>
                </div>
                <div class="col-lg-12 clickable-samples no_side_pad">
                  <p>{{$t('search-samples')}}:</p>
                  <ul>
                    <li v-on:click="setQueryText('email'); searchByKey()">Email</li>
                    <li v-on:click="setQueryText('ip'); searchByKey()">IP</li>
                    <li v-on:click="setQueryText('robinson'); searchByKey()">Robinson</li>
                    <li v-on:click="setQueryText('name'); searchByKey()">Name</li>
                    <li v-on:click="setQueryText('mobile'); searchByKey()">Mobile</li>
                    <li v-on:click="setQueryText('distance'); searchByKey()">Distance</li>
                    <li v-on:click="setQueryText('08014'); searchByKey()">08014</li>
                    <li v-on:click="setQueryText('test@uproc.io'); searchByKey()">Test@uproc.io</li>
                    <li v-on:click="setQueryText('carto.com'); searchByKey()">Carto.com</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div v-if="profile === 'technical'">
            <br><br>
            <div v-if="mode === 'fields'">
              <h5>{{$t('fields-available')}}</h5>
            </div>
            <div v-if="mode !== 'fields'">
              <h5 v-if="!query.length">{{$t('groups-available')}}</h5>
              <h5 v-if="query.length">{{$t('tools-available')}}</h5>
            </div>
          </div>
          <div class="row no_side_margin no_side_pad" v-if="this.isTechnicalProfile() && !query.length && isGroupsView() && groups.length">
            <div class="col-lg-3 wrapper" v-for="group in this.groups" v-bind:key="group.name">
              <a v-bind:href="'#/tools/category/' + group.name">
                <div class="all-tool-item">
                  <IconGroup :group="group.name" />
                  <h6>{{group.translated}}</h6>
                  <div class="tool-text">{{$t('group-' + group.name + '-description')}}</div>
                </div>
              </a>
            </div>
            <CustomProcessor />

          </div>

          <div class="row no_side_margin no_side_pad" v-if="this.isTechnicalProfile() && !query.length && isFieldsView() && params.length">
            <div class="col-lg-3 wrapper" v-for="param in this.params" v-bind:key="param">
              <a v-bind:href="'#/tools/field/' + param">
                <div class="all-tool-item">
                  <IconParam :param="param" />
                  <h6>{{param}}</h6>
                </div>
              </a>
            </div>
            <CustomProcessor />

          </div>

          <div class="row no_side_margin no_side_pad" v-if="this.isTechnicalProfile() && query.length && filtered.all.length && !manualTrigger">
            <div class="col-lg-3 wrapper" v-for="(processor) in this.filtered.all" v-bind:key="processor.key">
              <a v-bind:href="'#/tools/processor/' + processor.type + '/' + processor.module + '/' + processor.name + '/' + query">
                <div class="all-tool-item">
                  <IconGroup :group="processor.groups[0]" />
                  <span>{{ $t("type-"+ processor.type + "-verb" ) }}</span>
                  <h6>{{processor.description}}</h6>
                </div>
              </a>
            </div>
            <CustomProcessor />
          </div>

          <div class="row no_side_margin last-tools" v-if="this.isTechnicalProfile() && this.hasView() && !this.query.length && this.groups.length && this.lastProcessors.length">

            <div class="col-lg-12  no_side_pad">
              <h5>{{$t('tools-added')}}</h5>
            </div>
            <div class="col-lg-3 wrapper" v-for="(processor) in this.lastProcessors" v-bind:key="processor.key">
              <a v-bind:href="'#/tools/processor/' + processor.type + '/' + processor.module + '/' + processor.name">
                <div class="all-tool-item">
                  <IconGroup :group="processor.groups[0]" />
                  <span>{{ $t("type-"+ processor.type + "-verb" ) }}</span>
                  <h6>{{processor.description}}</h6>
                </div>
              </a>
            </div>
          </div> 
        </div>
      </div>
      <!-- wizard content start end -->
      <Footer />
    </div>
  </div>
</div>
</template>

<script>
import CustomProcessor from '@/components/Tools/CustomProcessor.vue'
import IconGroup from '@/components/Tools/IconsGroup.vue'
import IconParam from '@/components/Tools/IconsParam.vue'
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import Sidebar from '@/components/Sidebar.vue'

import axios from 'axios';

export default {
  name: 'tools',

  components: {
    IconGroup,
    IconParam,
    CustomProcessor,
    Header,
    Sidebar,
    Footer
  },
  data() {
    return {
      loading: true,
      isActive: false,
      isActiveIcon: '<',
      profile: '',
      profileSelectedProcessors: null,
      processors: [],
      mode: "",
      query: "",
      oldQuery: "",
      sending: "",
      isExpandedView: 0,
      categories: [],
      groups: [],
      lastProcessors: [],
      filtered: {},
      filteredProcessors: [],
      params: null,
      isSearchByValue: null,
      normalizersCount: null,
      checkersCount: null,
      gettersCount: null,
      isProcessorType: 0,
      routeParams: [],
      sendingRequestProcessors: false,
      paramNames: [],
      sendingRequestCategories: false,
      predictive: "",
      oldPredictive: null,
      hasToBeSent: false,
      config: {},
      manualTrigger: false,



    }

  },
  mounted() {
    this.config = {
      headers: {
        "Authorization": "Basic " + btoa(localStorage.configEmail + ":" + localStorage.token),
        "Content-Type": "application/json"
      }
    };

    this.enableInterceptor();

    this.getProcessorDetails();

    this.mode = 'groups';

    this.filtered = {
      all: [],
      checkers: [],
      getters: [],
      senders: [],
      favorites: [],
      own: []
    };
    //this.getProcessors();
    this.categories = []; //getCategories();
    this.getGroups();

    this.updatedFilteredCallback();

  },

  watch: {

    processors: function() {

      if (localStorage.toolsProfile) {
        this.setProfile(localStorage.toolsProfile);
      }

    }

  },
  methods: {

    enableInterceptor() {
      this.axiosInterceptor = axios.interceptors.request.use((config) => {

        this.$root.$emit('isLoading', true);

        return config
      }, (error) => {

        this.$root.$emit('isLoading', false);

        return Promise.reject(error)
      })

      axios.interceptors.response.use((response) => {

        this.$root.$emit('isLoading', false);

        return response
      }, function(error) {

        this.$root.$emit('isLoading', false);

        return Promise.reject(error)
      })
    },

    getEmail: function() {
      return this.user ? this.user.email : localStorage.configEmail ? localStorage.configEmail : "test@uproc.io";
    },

    menuCollapse: function() {
      this.isActive = !this.isActive;
      this.$root.$emit('isActived', this.isActive);

      if (this.isActive)
        this.isActiveIcon = '>'
      else
        this.isActiveIcon = '<'
    },

    getProcessorDetails: function() {
    //this.$store.dispatch('loadProcessors')
      //var url = '/api/v2/processor/search';
      console.log(this.$locale)
      var url = '/json/' + this.$locale + '/processors.json?' + this.$prefix;
      //this.$http.get(url, this.config).then((results) => {
      this.$http({method: 'GET', url: url, baseURL: '/'}).then((results) => {
        this.processors = results.data.processors;
      });
    },

    searchProcessors: function() {

      //var url = '/api/v2/processor/search';
      var url = '/json/' + this.$locale + '/processors.json?' + this.$prefix;
      this.loading = true;
      //this.$http.get(url, this.config).then((results) => {
      this.$http({method: 'GET', url: url, baseURL: '/'}).then((results) => {
        this.sendingRequestProcessors = false;
        this.processors = results.data.processors; //alert(this.processors.length);
        this.paramNames = this.getUniqueAvailableParamNames(this.processors);
        this.setLastProcessors();
        //$rootScope.$broadcast('search.processors.loaded');
        this.updateFilteredProcessors();
        this.loading = false;
      });

    },

    setLastProcessors: function() {

      var compareDates = function(a, b) {
        if (a.date.toString() < b.date.toString()) return 1;
        if (a.date.toString() > b.date.toString()) return -1;
        return 0;
      }


      this.lastProcessors = this.processors.filter(function(processor) {
        return processor.date !== null;
      }).sort(compareDates).slice(0, 12);

    },

    getUniqueAvailableParamNames: function(items) {
      //alert('ds');
      var params = [];
      if (items) {
        items.forEach(function(p) {
          var paramNames = p.params.map(function(p) {
            return p.name.replace(/[0-9]+/g, '');
          });
          var firstParam = paramNames.length > 0 ? paramNames[0] : "";
          if (params.indexOf(firstParam) === -1) {
            params.push(firstParam);
          }
          /*
          paramNames.forEach(function(param) {
            if (params.indexOf(param) === -1) {
              params.push(param);
            }
          });
          */
        });
      }
      return params.sort();

    },

    searchCategories: function() {
      //var url = '/api/v2/category/search'
      var url = '/json/' + this.$locale + '/groups.json?' + this.$prefix;
      //this.$http.get(url, this.config).then((results) => {
      this.$http({method: 'GET', url: url, baseURL: '/'}).then((results) => {
        this.sendingRequestCategories = false;
        this.categories = results.data.categories; //.sort(sortWithLocaleCompare);
        //$rootScope.$broadcast('search.categories.loaded');
      });
    },

    searchGroups: function() {

      //var url = '/api/v2/group/search'
      var url = '/json/' + this.$locale + '/groups.json?' + this.$prefix;
      //this.$http.get(url, this.config).then((results) => {
      this.$http({method: 'GET', url: url, baseURL: '/'}).then((results) => {
        this.sendingRequestGroups = false;
        this.groups = results.data.groups.sort();
        //$rootScope.$broadcast('search.groups.loaded');
      });

    },

    predictFieldName: function(value) {

      var url = '/api/v2/field/search/' + value

      this.$http.get(url, this.config).then((results) => {
        this.updateFilteredProcessors(results.data.name, true);
      });

    },

    initProfile: function() {
      this.profile = localStorage.toolsProfile || "";
    },

    setProfile: function(profile) {
      this.profile = profile;
      localStorage.toolsProfile = this.profile;
      //this.resetQueryText();
      this.profileSelectedProcessors = this.getProfileSelectedProcessors(this.profile);
    },

    getProfileSelectedProcessors: function(profile) {

      if (this.processors) {
        return this.processors.filter(function(p) {
          return p.targets.indexOf(profile) !== -1;
        });
      } else {
        return [];
      }

    },

    hasProfile: function() {

      return this.profile !== '';

    },

    resetProfile: function() {

      this.profile = '';
      this.resetQueryText()

      localStorage.toolsProfile = this.profile;

    },

    isTechnicalProfile: function() {

      return this.profile === 'technical';

    },

    hasView: function() {

      return this.mode !== '';

    },

    toggleViewMode: function(mode) {

      this.mode = mode;
      this.query = "";
      if (this.mode === 'fields') {
        this.params = this.getParamNames();
      }
    },

    onQueryChanged: function() {

      //this.filtered.all = [];
      //this.resetQueryText();
      this.searchSent = this.query.length === 0;
      this.mode = "groups";
      //this.setQueryText(this.query);
    },

    resetQueryText: function() {

      this.query = "";
      this.oldQuery = "impossible_value_to_be_found";
      //StorageService.setValue("search.query", "");
      this.filterProcessors(this.query);

    },

    updatedFilteredCallback: function() {

      this.sending = false;
      this.loadBusinessValues();
      if (this.filtered && !this.filtered.all.length) {
        this.isSearchByValue = true;
        this.sending = true;
        this.doPredictiveSearch(this.query);
      } else {
        this.sending = false;

      }

    },

    doPredictiveSearch: function(predictiveText) {

      this.oldPredictive = this.predictive;
      this.predictive = predictiveText;
      if (this.predictive.length && this.isNewPredictiveSearch()) {
        this.predictFieldName(encodeURIComponent(predictiveText));
      }

    },

    getCategories: function() {

      if (this.categories && !this.categories.length && !this.sendingRequestCategories) {
        this.sendingRequestCategories = true;
        this.searchCategories();
        return this.categories;
      } else {
        return this.categories;
      }

    },

    getGroups: function() {

      if (this.groups && !this.groups.length && !this.sendingRequestGroups) {
        this.sendingRequestGroups = true;
        this.searchGroups();
        return this.groups;
      } else {
        return this.groups;
      }

    },

    filterByCategory: function(processors, category) {
      return processors.filter(function(item) {
        return item.groups[0] === category;
      });
    },

    filterByParam: function(processors, param) {
      return processors.filter(function(item) {
        return item.params.filter(function(p) {
          return p.name.indexOf(param) === 0;
        }).length > 0;
      });
    },

    loadBusinessValues: function() {

      this.isProcessorType = this.getProcessorType();
      this.isExpandedView = this.getExpandedView();
      this.query = this.routeParams.key && this.routeParams.key.length ? this.routeParams.key : this.getQueryText();
      this.setQueryText(this.query);
      this.categories = this.getCategories();
      this.groups = this.getGroups();
      this.processors = this.getProcessors();
      this.lastProcessors = this.getLastProcessors();
      this.filtered = this.getFiltered();
      this.filteredProcessors = this.filtered.all;
      this.params = this.getParamNames(); //alert('here');

      var category = this.routeParams.category;
      var type = this.routeParams.type;
      var field = this.routeParams.field;

      var filterValue = category && category.length && category !== 'all' ? category : (field || "");
      if (this.filteredProcessors && this.filteredProcessors !== undefined && filterValue.length) {
        var fn = category && category.length ? this.filterByCategory : this.filterByParam;
        this.filteredProcessors = fn(this.filteredProcessors, filterValue);
      }

      //Filter by type
      if (this.filteredProcessors && this.filteredProcessors !== undefined && type && type.length) {
        this.filteredProcessors = this.filterByType(this.filteredProcessors, type);
      }

      this.countFilteredProcessorsByType();
      this.isSearchByValue = false;

    },

    filterByType: function(processorss, type) {
      return processorss.filter(function(item) {
        return type === 'normalize' ? item.name.indexOf('normalize') !== -1 : item.type === type && item.name.indexOf('normalize') === -1;
      });
    },

    countFilteredProcessorsByType: function() {
      this.normalizersCount = this.filterByType(this.filteredProcessors, 'normalize').length;
      this.checkersCount = this.filterByType(this.filteredProcessors, 'check').length;
      this.gettersCount = this.filterByType(this.filteredProcessors, 'get').length;

    },

    getProcessors: function(forced) {

      forced = forced || false;
      if (!this.sendingRequestProcessors && (!this.processors.length || forced)) {
        this.sendingRequestProcessors = true;
        this.searchProcessors();
        return this.processors;
      } else {
        return this.processors;
      }

    },

    getProcessorType: function() {

      return this.isProcessorType;

    },

    isGroupsView: function() {

      return this.mode === 'groups';

    },

    isFieldsView: function() {

      return this.mode === 'fields';

    },

    getExpandedView: function() {

      return this.isExpandedView;

    },

    getQueryText: function() {

      return this.query;

    },

    getLastProcessors: function() {

      return this.lastProcessors;

    },

    getFiltered: function() {

      return this.filtered;

    },

    getParamNames: function() {

      return this.paramNames;

    },

    isNewPredictiveSearch: function() {

      return this.oldPredictive !== this.predictive;

    },

    searchByKey: function() {

      this.onQueryChanged();
      this.isSearchByValue = false;
      this.hasToBeSent = false;
      this.sending = true;
      this.setQueryText(this.query);
      this.searchSent = true;

    },

    setQueryText: function(txt) {
      //resetQueryText();
      this.oldQuery = this.query;
      this.query = txt;
      //this.mode = 'groups'

      if (this.isNewQueryText()) {
        //StorageService.setValue("search.query", query);
        this.updateFilteredProcessors(txt);
      }

    },

    setQueryTextManual: function() {
      //resetQueryText();
      this.manualTrigger = true;
    },

    setQueryTextManualEnter: function(txt) {
      //resetQueryText();
      this.manualTrigger = false;

      //this.oldQuery = this.query;
      this.query = this.query; //this.oldQuery;

      if (this.isNewQueryText()) {
        //StorageService.setValue("search.query", query);
        this.updateFilteredProcessors(txt);
      }
    },

    isNewQueryText: function() {

      return this.oldQuery !== this.query;

    },

    updateFilteredProcessors: function(txt, byParam) {
      this.$root.$emit('isLoading', true);
      txt = txt || this.query;
      byParam = byParam || false;
      if (this.processors && this.processors.length) {
        this.filterProcessors(txt, byParam);
        //$rootScope.$broadcast('search.updatedFiltered');
      }
      this.$root.$emit('isLoading', false);
    },

    isCheck: function(fruit) {
      return fruit.type === 'check';
    },

    isGet: function(fruit) {
      return fruit.type === 'get';
    },

    isSend: function(fruit) {
      return fruit.type === 'send';
    },

    filterProcessors: function(txt) {
      this.$root.$emit('isLoading', true);

      var sortProcessorByModuleAndName = function(processorA, processorB) {
        var processorModuleAndNameA = processorA.description ? processorA.description.toLowerCase() : processorA.name.toLowerCase();
        var processorModuleAndNameB = processorB.description ? processorB.description.toLowerCase() : processorB.name.toLowerCase();
        if (processorModuleAndNameA > processorModuleAndNameB) {
          return 1;
        } else if (processorModuleAndNameA < processorModuleAndNameB) {
          return -1;
        } else {
          return 0;
        }
      }

      txt = txt || this.query;

      var filteringcheck = []
      var filteringget = []
      var filteringsend = []

      if (txt.length) {
        var nonSorted = this.filterMultiple(this.processors, {
          module: txt,
          name: txt,
          cleaned_description: txt,
          description: txt,
          description_en: txt,
          extended_description: txt
        }, 'or');

        this.filtered.all = this.cleanDuplicatedResults(nonSorted).sort(sortProcessorByModuleAndName);
        filteringcheck = this.filtered.all.filter(this.isCheck);
        filteringget = this.filtered.all.filter(this.isGet);
        filteringsend = this.filtered.all.filter(this.isSend);

        this.filtered.checkers = this.filtered.all.length ? filteringcheck.sort(sortProcessorByModuleAndName) : [];
        this.filtered.getters = this.filtered.all.length ? filteringget.sort(sortProcessorByModuleAndName) : [];
        this.filtered.senders = this.filtered.all.length ? filteringsend.sort(sortProcessorByModuleAndName) : [];

        if (!this.filtered.all.length) {
          this.updatedFilteredCallback();
        }

      } else {
        this.filtered.all = this.processors.sort(sortProcessorByModuleAndName);

        filteringcheck = this.filtered.all.filter(this.isCheck);
        filteringget = this.filtered.all.filter(this.isGet);
        filteringsend = this.filtered.all.filter(this.isSend);

        this.filtered.checkers = this.filtered.all.length ? filteringcheck.sort(sortProcessorByModuleAndName) : [];
        this.filtered.getters = this.filtered.all.length ? filteringget.sort(sortProcessorByModuleAndName) : [];
        this.filtered.senders = this.filtered.all.length ? filteringsend.sort(sortProcessorByModuleAndName) : [];
      }

      this.filtered.favorites = [];
      this.filtered.own = [];
      this.isProcessorType = this.hasNoProcessorsInSelectedProcessorType() ?
        0 :
        this.isProcessorType;
      this.$root.$emit('isLoading', false);

    },

    cleanDuplicatedResults: function(items) {

      var newItems = [];
      for (var index in items) {
        var item = items[index];
        var key = item.type + "-" + item.module + "-" + item.name;
        newItems[key] = item;
      }

      var finalArray = [];
      var i = 0;
      for (var key2 in newItems) {
        finalArray[i++] = newItems[key2];
      }
      return finalArray;

    },

    hasNoProcessorsInSelectedProcessorType: function() {

      return (
        (this.isProcessorType === 0 && this.filtered.all.length === 0) ||
        (this.isProcessorType === 1 && this.filtered.checkers.length === 0) ||
        (this.isProcessorType === 2 && this.filtered.getters.length === 0) ||
        (this.isProcessorType === 3 && this.filtered.favorites.length === 0) ||
        (this.isProcessorType === 4 && this.filtered.own.length === 0)
      );

    },

    filterMultiple: function(items, keyObj, operand) {
      operand = operand || 'or';
      var removeAccents = function(value) {
        return value
          .replace(/á/g, 'a')
          .replace(/â/g, 'a')
          .replace(/é/g, 'e')
          .replace(/è/g, 'e')
          .replace(/ê/g, 'e')
          .replace(/í/g, 'i')
          .replace(/ï/g, 'i')
          .replace(/ì/g, 'i')
          .replace(/ó/g, 'o')
          .replace(/ô/g, 'o')
          .replace(/ú/g, 'u')
          .replace(/ü/g, 'u')
          .replace(/ç/g, 'c')
          .replace(/ß/g, 's');
      };

      var filterObj = {
        operand: operand,
        data: items,
        filteredData: [],
        applyFilter: function(obj, key) {
          function isCheck(fruit) {
            return fruit[key] && fruit[key].indexOf ? fruit[key].indexOf(fObj[key]) !== -1 : fruit.key.includes(fObj[key]);
          }
          var fData = [];
          this.filteredData = (!this.filteredData.length && operand === 'and') ? this.data : this.filteredData;
          if (obj) {
            var fObj = {};
            if (!Array.isArray(obj)) {
              fObj[key] = obj;
              fData = fData.concat(this.data.filter(isCheck));
            } else if (Array.isArray(obj)) {
              if (obj.length) {
                for (var i = 0; i < obj.length; i++) {
                  if (obj[i]) {
                    fObj[key] = obj[i];
                    fData = fData.concat(this.data.filter(isCheck));
                  }
                }
              }
            }
            if (fData.length) {
              this.filteredData = (operand === 'or') ? fData.concat(this.filteredData) : fData;
            }
          }
        }
      };
      if (keyObj) {
        Object.keys(keyObj).forEach(function(key) {
          var valueWithoutAccent = removeAccents(keyObj[key]);
          filterObj.applyFilter(valueWithoutAccent, key);
          if (!filterObj.filteredData.length) {
            filterObj.applyFilter(keyObj[key], key);
          }
        });
      }
      return filterObj.filteredData;
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
@import '../../assets/css/tools.css';
@import '../../assets/css/global.css';
</style>
