<template>
    <div v-if="showAnnouncement" class="announcement">
        <p v-html="message"></p>
        <button @click="closeAnnouncement" class="close-btn">Ok</button>
    </div>
</template>

<script>
export default {
    name: 'Announce',
    data() {
        return {
            messages: {
                en: 'We are working on new features! List and forms have been disabled. Stay tuned for updates! 🎉',
                es: '¡Estamos trabajando en nuevas funciones! Las listas y formularios han sido deshabilitados. ¡Está atento a las actualizaciones! 🎉'
            },
            showAnnouncement: false,
            message: ''
        };
    },
    created() {
        this.showAnnouncement = this.shouldShowAnnouncement();
        this.message = this.getAnnouncementMessage();
    },
    methods: {
        getAnnouncementMessage() {
            return this.messages[this.$i18n.locale];
        },
        closeAnnouncement() {
            console.log('closeAnnouncement method called');
            this.showAnnouncement = false;
            const timestamp = new Date().getTime();
            localStorage.setItem('announcementSeen', timestamp);
        },
        shouldShowAnnouncement() {
            // const timestamp = localStorage.getItem('announcementSeen');
            // if (!timestamp) {
            //     return true;
            // }
            // const currentTime = new Date().getTime();
            // const fourDaysInMilliseconds = 4 * 24 * 60 * 60 * 1000;
            // if (currentTime - timestamp > fourDaysInMilliseconds) {
            //     localStorage.removeItem('announcementSeen');
            //     return true;
            // }
            // return false;
            return false;
        }
    }
};
</script>

<style scoped>
.announcement {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: #0300e2;
    color: #fff;
    text-align: center;
    padding: 10px;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    opacity: 0.9;
    font-size: 15px;
}
.announcement p {
    margin: 0;
    flex-grow: 1;
}
.announcement button {
    margin-top: 10px;
    padding: 5px 10px;
    background-color: #fff;
    color: #000;
    border: none;
    cursor: pointer;
}
</style>