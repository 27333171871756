<template>
<div class="row">
    <div class="col-lg-12">
        <div class="select-file">
            <p class="text-left pb-4">{{ $t('wizard-authorized-ip') }}</p>
            
            <div class="form-group row">
                <div for="host" class="col-sm-2 text-left">{{ $t('sftp-host') }}</div>
                <div class="col-sm-10">
                    <input type="text" id="host" v-model="details.host" :placeholder="$t('sftp-host-placeholder')" class="form-control">
                </div>
            </div>
            <div class="form-group row mt-2">
                <div for="port" class="col-sm-2 text-left">{{ $t('sftp-port') }}</div>
                <div class="col-sm-10">
                    <input type="text" id="port" v-model="details.port" :placeholder="$t('sftp-port-placeholder')" class="form-control">
                </div>
            </div>
            <div class="form-group row mt-2">
                <div for="username" class="col-sm-2 text-left">{{ $t('sftp-username') }}</div>
                <div class="col-sm-10">
                    <input type="text" id="username" v-model="details.username" :placeholder="$t('sftp-username-placeholder')" class="form-control">
                </div>
            </div>
            <div class="form-group row mt-2">
                <div for="password" class="col-sm-2 text-left">{{ $t('sftp-password') }}</div>
                <div class="col-sm-10">
                    <input type="password" id="password" v-model="details.password" :placeholder="$t('sftp-password-placeholder')" class="form-control">
                </div>
            </div>
            <div class="form-group row mt-2">
                <div for="filePath" class="col-sm-2 text-left">{{ $t('sftp-filepath') }}</div>
                <div class="col-sm-10">
                    <input type="text" id="filePath" v-model="details.filePath" :placeholder="$t('sftp-filepath-placeholder')" class="form-control">
                </div>
            </div>


        </div>
        <ChangeSource currentType="sftp" v-on:change-source="setSource" />
    </div>
</div>
</template>
<script>
import ChangeSource from '@/components/Wizards/sources/ChangeSource.vue';
 export default {
    name: 'source-url',
    data() {
        return {
            details: {
                host: '',
                port: '22',
                username: '',
                password: '',
                filePath: ''
            }
        };
    },
    components: {
        ChangeSource
    },
    methods: {
        setSource(source) {
            this.$emit('change-source', source);
        },
        analyze() {
            this.$emit('upload', this.details);
        }
    }
};
</script>
