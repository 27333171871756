<template>
  <div>
    <Header />

    <div class="row no_side_margin content">
      <Sidebar />

      <div
        class="col-md-9 col-lg-10 no_side_pad"
        v-bind:class="{ collapse_active_footer: isActive }"
      >
        <button @click="menuCollapse" class="collapse-btn">
          {{ isActiveIcon }}
        </button>
        <div class="row no_side_margin">
          <div class="total col-lg-12">
            <ul class="nav nav-tabs d_summary">
              <li class="nav-item active">
                <a
                  data-toggle="tab"
                  href="#bulks"
                  class="nav-link"
                  v-bind:class="{ active: section === 'bulks' }"
                  v-on:click="setSectionMenu('bulks')"
                >
                  <font-awesome-icon icon="tasks" size="lg" />
                  {{ $t("bulks") }}</a
                >
              </li>

              <li class="nav-item active">
                <a
                  data-toggle="tab"
                  href="#lists"
                  class="nav-link"
                  v-bind:class="{ active: section === 'lists' }"
                  v-on:click="setSectionMenu('lists')"
                >
                  <font-awesome-icon icon="users" size="lg" />
                  {{ $t("lists") }}</a
                >
              </li>
              
              <li class="nav-item active">
                <a
                  data-toggle="tab"
                  href="#tables"
                  class="nav-link"
                  v-bind:class="{ active: section === 'tables' }"
                  v-on:click="setSectionMenu('tables')"
                  v-if="isAdmin"
                >
                  <font-awesome-icon icon="table" size="lg" />
                  {{ $t("tables") }}</a
                >
              </li>
              
              
            </ul>
            <Bulks v-if="section === 'bulks'"/>
            <Lists v-if="section === 'lists'" :section="section" />
            <Tables v-if="section === 'tables'" />
          </div>
        </div>
        <Footer />
      </div>
    </div>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import Sidebar from "../Sidebar.vue";

import Bulks from "./Bulks/Index.vue";
import Lists from "./Lists/Index.vue";
import Tables from "./Tables/Index.vue";

export default {
  name: "mydata",
  data() {
    return {
      isActive: false,
      isActiveIcon: "<",
      config: {},
      section: "bulks",
      isAdmin: false,
    };
  },
  mounted() {
    var path = this.$route.path;
    if (path.indexOf("bulks") !== -1) {
      this.section = "bulks";
    } else if (path.indexOf("/lists/") !== -1) {
      this.section = "lists";
    } else if (path.indexOf("/lists") !== -1) {
      this.section = "lists";
    } else if (path.indexOf("tables") !== -1) {
      this.section = "tables";
    }

    this.setSection(this.section);
    this.getUserDetails();
  },
  components: {
    Header,
    Sidebar,
    Footer,

    Bulks,
    Lists,
    Tables,
  },
  methods: {
    menuCollapse: function () {
      this.isActive = !this.isActive;
      this.$root.$emit("isActived", this.isActive);

      if (this.isActive) this.isActiveIcon = ">";
      else this.isActiveIcon = "<";
    },

    setSection: function (section) {
      this.section = section;
    },

    setSectionMenu: function (section) {
      this.setSection(section);
      this.$router.push({ path: "/mydata/" + section });
    },

    getConfigAuth: function () {
      return {
        headers: {
          Authorization:
            "Basic " +
            btoa(localStorage.configEmail + ":" + localStorage.token),
          "Content-Type": "application/json",
        },
      };
    },

    getUserDetails: function () {
      var url = "/api/v2/profile";
      this.$http.get(url, this.getConfigAuth()).then((results) => {
        this.user = results.data;
        this.isAdmin = this.user.role === "admin";
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
@import "../../assets/css/wizards.css";
@import "../../assets/css/global.css";
</style>
