<template>
    <div class="pt-4">
        {{ $t('wizard-welcome-cases' ) }}
        <div class="row text-center">
            <div v-for="localReason in reasons" v-bind:key="localReason.key" class="col-lg-2 text-center p-4">
                <button v-on:click="setReason(localReason.key)" class="btn btn-primary w-200 h-100 text-sm" :disabled="reason === localReason.key">
              {{localReason.text}}
              </button>
            </div>
        </div>
    </div>
</template>

<script>
import i18n from '@/plugins/i18n';

export default {
  name: 'welcome',
    props: ['reason'],  
  data() {
    return {
      reasons: ["My email marketing campaigns", "My sms marketing campaigns", "My existing data", "My recruitment process", "My sales funnel"].map(function(reason) {
//        reasons: ["My email marketing campaigns", "My sms marketing campaigns", "My existing data", "My e-shop catalog"].map(function(reason) {
          return {
          key: reason.replace(/ /g, "-").toLowerCase(),
          text: i18n.t('feedback-reason-' + reason)
        }
      }).sort((a, b) => (a.text > b.text) ? 1 : -1),
    }
  },
  async created() {

  },
  mounted() {

  },
  components: {

  },

  methods: {
    setReason: function(reason) {
        this.$emit("change-reason", reason);
    }
  }

}
</script>