<template>
<div class="row">
    <div class="col-lg-12">
        <div class="select-file">
            <p class="text-left pb-4">{{ $t('wizard-authorized-ip') }}</p>
            
            <div class="form-group row">
                <div :for="'host'" class="col-sm-2 text-left">{{ $t('postgres-host') }}</div>
                <div class="col-sm-10">
                    <input type="text" id="host" v-model="details.host" @input="checkFormValidity" :placeholder="$t('postgres-host-placeholder')" class="form-control">
                </div>
            </div>
            
            <div class="form-group row mt-2">
                <div :for="'port'" class="col-sm-2 text-left">{{ $t('postgres-port') }}</div>
                <div class="col-sm-10">
                    <input type="text" id="port" v-model="details.port" @input="checkFormValidity" :placeholder="$t('mysql-port-placeholder')" class="form-control">
                </div>
            </div>

            <div class="form-group row mt-2">
                <div :for="'username'" class="col-sm-2 text-left">{{ $t('postgres-username') }}</div>
                <div class="col-sm-10">
                    <input type="text" id="username" v-model="details.username" @input="checkFormValidity" :placeholder="$t('mysql-username-placeholder')" class="form-control">
                </div>
            </div>
            
            <div class="form-group row mt-2">
                <div :for="'password'" class="col-sm-2 text-left">{{ $t('postgres-password') }}</div>
                <div class="col-sm-10">
                    <input type="password" id="password" v-model="details.password" @input="checkFormValidity" :placeholder="$t('postgres-password-placeholder')" class="form-control">
                </div>
            </div>
            
            <div class="form-group row mt-2">
                <div :for="'database'" class="col-sm-2 text-left">{{ $t('postgres-database') }}</div>
                <div class="col-sm-10">
                    <input type="text" id="database" v-model="details.database" @input="checkFormValidity" :placeholder="$t('postgres-database-placeholder')" class="form-control">
                </div>
            </div>

            <div class="form-group row mt-2">
                <div :for="'table'" class="col-sm-2 text-left">{{ $t('postgres-table') }}</div>
                <div class="col-sm-10">
                    <input type="text" id="table" v-model="details.table" @input="checkFormValidity" :placeholder="$t('postgres-table-placeholder')" class="form-control">
                </div>
            </div>

            <div class="form-group row mt-2">
                <div :for="'columns'" class="col-sm-2 text-left">{{ $t('postgres-columns') }}</div>
                <div class="col-sm-10">
                    <input type="text" id="columns" v-model="details.columns" @input="checkFormValidity" :placeholder="$t('postgres-columns-placeholder')" class="form-control">
                </div>
            </div>
            
            
        </div>
        <ChangeSource currentType="postgres" v-on:change-source="setSource" />
    </div>
</div>
</template>
<script>
import ChangeSource from '@/components/Wizards/sources/ChangeSource.vue';

export default {
    name: 'source-postgres',
    data() {
        return {
            isFormValid: false,
            details: {
                host: '',
                port: '3306',
                database: '',
                table: '',
                username: '',
                password: ''
            }
        };
    },
    components: {
        ChangeSource
    },
    methods: {
        checkFormValidity() {
            this.isFormValid = this.details.host.length > 0 && 
                       this.details.port.length > 0 && 
                       this.details.database.length > 0 && 
                       this.details.username.length > 0 && 
                       this.details.password.length > 0;
        },
        setSource(source) {
            this.$emit('change-source', source);
        },
        analyze() {
            this.$emit('upload', this.details);
        }
    }
};
</script>
