<template>
  <div class="tab-content">
    <div id="home" class="tab-pane container active">
      <div class="pb-4">
        <div class="row">
          <div class="col-lg-9">
            <a href="#/wizard/file" class="btn btn-primary">
              <font-awesome-icon icon="magic" size="lg"/>
              &nbsp;
              {{ $t("wizard-new-file") }}
            </a>
          </div>
          <div class="col-lg-3">
           
            <div class="select_period col-text mr-2">
              <p class="text">{{ $t('select-period') }}:</p>
            </div>
            <div class="">
                <select v-model="period" class="form-control" v-on:change="changePeriod(period)">
                <!-- <option value="today">{{ $t('select-period-today') }}</option>
                <option value="yesterday">{{ $t('select-period-yesterday') }}</option> -->
                <option value="thisweek">{{ $t('select-period-thisweek') }}</option>
                <option value="previousweek">{{ $t('select-period-previousweek') }}</option>
                <option value="thismonth">{{ $t('select-period-thismonth') }}</option>
                <option value="previousmonth">{{ $t('select-period-previousmonth') }}</option>
                <option value="thisquarter">{{ $t('select-period-thisquarter') }}</option>
                <option value="previousquarter">{{ $t('select-period-previousquarter') }}</option>
                <option value="thisyear">{{ $t('select-period-thisyear') }}</option>
                <option value="previousyear">{{ $t('select-period-previousyear') }}</option>
                <!-- <option value="custom">{{ $t('select-period-custom') }}</option> -->
                </select>
            </div>
          </div>
        </div>
      </div>
<!-- 
      <div class="row pb-2">
          <div
            class="col-lg-12 col-md-12"
            v-if="jobs.length > 0"
            v-html="
              $t('list-pagination', {
                startItems: jobs.length,
                finishItems: jobs.length,
              })
            "
          ></div>
      </div> -->

      <div
        class="col-lg-12 no_side_pad request"
        v-if="this.jobs && this.jobs.length"
      >
        <div class="page-nav">
          <div class="row no_side_margin">
            <div class="col-lg-2 col-sm-4 col-4 text-right">
              <div>
                <a
                  :class="{ disabled: isFirstPage(), '': !isFirstPage() }"
                  v-on:click="movePrevious()"
                >
                  <span class="nav-icon">←</span> {{ $t("previous") }}
                </a>
              </div>
            </div>

            <div class="col-lg-8 col-sm-4 col-4 no_side_pad text-center">
              {{ $t("page") }} {{ this.currentPage + 1 }} /
              {{ this.totalPages }}
            </div>

            <div class="col-lg-2 col-sm-4 col-4 no_side_pad">
              <div>
                <a
                  :class="{ disabled: isLastPage(), '': !isLastPage() }"
                  v-on:click="moveNext()"
                >
                  {{ $t("next") }} <span class="nav-icon">→</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row smry-status no_side_margin" style="overflow-x: auto;">
        <table class="table table-striped list-table" style="margin-top: 0px">
          <thead>
            <tr>
              <th class="t-head">{{ $t("date") }}</th>
              <th class="t-head">{{ $t("processor") }}</th>
              <th class="t-head">{{ $t("status") }}</th>
              <th class="t-head text-right">{{ $t("rows") }}</th>
              <th class="t-head text-right">{{ $t("cost") }}</th>
              <th class="t-head text-right"></th>
            </tr>
          </thead>

          <tbody v-if="!this.jobs.length">
            <tr>
              <td colspan="7" class="text-center table-col">
                {{ $t("no-results") }}
              </td>
            </tr>
          </tbody>

          <tbody v-if="this.jobs.length">
            <tr v-for="(job, index) in this.jobs" v-bind:key="index">
              <td class="table-col">{{ job.date | formatDate }}</td>
              <td class="table-col">
                <div
                  v-for="(processor, index) in job.processor"
                  v-bind:key="index"
                >
                  <a
                    v-bind:href="
                      '#/tools/processor/' +
                        processor.replace('-', '/').replace('-', '/')
                    "
                    target="_blank"
                    >{{ processor }}</a
                  >
                </div>
              </td>
              <td class="table-col" style="width: 300px;">
                <span v-if="job.status === 1" class="text-info">{{
                  $t("job-pending")
                }}</span>
                <span v-if="job.status === 2" class="text-warning">
                  <span v-if="job.progress === undefined">{{
                    $t("job-preparing")
                  }}</span>
                  <span v-else-if="job.progress >= 0 && job.progress < 100"
                    >{{ job.progress }}%</span
                  >
                  <span v-else>{{ $t("job-finishing") }}</span>
                </span>
                <span v-if="job.status === 3" class="text-success">{{
                  $t("job-finished")
                }}</span>
                <span v-if="job.status === 4" class="text-danger"
                  >{{ $t("job-error") }}: {{ job.statusMessage }}</span
                >
              </td>
              <td class="table-col text-right">{{ job.totalRows }}</td>
              <td
                class="table-col text-right"
                :class="{ 'text-warning': job.price > 0 }"
              >
                <span v-if="job.status === 3"
                  >{{ job.price.toFixed(2) | numberOnly }}{{ currency }}</span
                >
              </td>
              <td class="table-col">
                <a
                  v-if="job.status === 3 && job.id !== undefined"
                  v-on:click="downloadJobResultsFile(job.id)"
                  class="pointer pull-right mr-2"
                  title="Download results"
                  ><i class="fa fa-download"></i
                ></a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="col-lg-12 text-center">{{ $t("this-month-period") }}</div>
      <div
        class="col-lg-12 text-center"
        v-if="jobsRunning"
        :key="countdownToRefresh"
      >
        <small>{{
          $t("jobs-pending-refresh", { seconds: countdownToRefresh })
        }}</small>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "bulks",
  data() {
    return {

      orderByField: "startDate",
      reverseSort: true,
      startDate: "",
      endDate: "",
      pendingRequest: false,
      currentPage: 0,
      totalRecords: 0,
      totalPages: 0,
      totalRows: 0,
      totalRowsOk: 0,
      totalCost: 0,
      totalCredits: 0,
      totalSavings: 0,
      prevStartDate: null,
      prevEndDate: null,
      jobs: [],
      jobsRunning: false,
      countdownToRefresh: 30,
      secondsToRefresh: 30,
      tasks: [],
      loading: false,
      requests: [],

      period: "thisweek",

      pendingJobs: false,
      currency: "€",
    };
  },
  mounted() {
    this.startDate = new Date(Date.now() + -60 * 24 * 3600 * 1000);
    this.endDate = new Date();

    this.getJobsTrigger();
  },
  components: {},
  methods: {
    getJobsTrigger: function() {
      this.period = "thisweek";
      this.currentPage = 0;
      this.totalPages = 0;
      this.getJobs(true);
    },

    getConfigAuth() {
      return {
        headers: {
          Authorization:
            "Basic " +
            btoa(localStorage.configEmail + ":" + localStorage.token),
          "Content-Type": "application/json",
        },
      };
    },

    getEmail: function() {
      return this.user
        ? this.user.email
        : localStorage.configEmail
        ? localStorage.configEmail
        : "test@uproc.io";
    },

    downloadJobResultsFile: function(request_id) {
      var url = this.$apiBase + "/api/v2/request_download/" + request_id;
      this.$http.get(url, this.getConfigAuth()).then((results) => {
        if (
          results &&
          results.data &&
          results.data.url &&
          results.data.url.length
        ) {
          window.location.href = results.data.url;
        }
      });
    },

    setTimeoutJobs: function() {
      setTimeout(() => {
        this.clearCountdown();
        this.getJobs(true);
      }, 60000);
    },

    clearCountdown: function() {
      clearInterval(this.countdown);
      //clear all intervals
      for (var i = 1; i < 100; i++) {
        window.clearInterval(i);
      }
    },

    initCountdown: function() {
      this.countdownToRefresh = this.secondsToRefresh;
      this.countdown = setInterval(() => {
        this.countdownToRefresh--;
        if (this.countdownToRefresh === 0) {
          this.clearCountdown();
          this.getJobs(true);
        }
      }, 1000);
    },

    changePeriod: function(period) {
      this.period = period;
      this.currentPage = 0;
      this.totalPages = 0;
      this.getJobs(true);
    },

    getJobs: function(forced) {
      //alert(
      this.$root.$emit("isLoading", true);

      forced = this.areDatesDifferents() || forced || false;
      if (!this.pendingRequest && forced) {
        this.pendingRequest = true;
        var url =
          "/api/v2/jobs_period/" +
          this.period +
          "/" +
          this.startDate.getTime() +
          "/" +
          this.endDate.getTime() +
          "/" +
          this.currentPage;

        this.$http.get(url, this.getConfigAuth()).then((results) => {
          this.$root.$emit("isLoading", false);
          var result = results.data;
          this.jobs = result.results;
          this.totalRecords = result.count;
          this.totalPages = result.totalPages;
          this.rowsPerPage = result.rowsPerPage;
          this.pendingRequest = false;
          this.areDatesDifferents(true);
          this.calculateTotals();

          this.jobsRunning = this.jobs.some(
            (job) => job.status === 2 || job.status === 1
          );
          this.areDatesDifferents(true);
          this.countdownToRefresh = this.secondsToRefresh;

          if (this.jobsRunning) {
            this.setTimeoutJobs();
            this.initCountdown();
          }
        });
      } else {
        this.calculateTotals();
      }
    },

    areDatesDifferents: function(setDates) {
      setDates = setDates || false;
      var resStart = this.isStartDateDifferent(),
        resEnd = this.isEndDateDifferent();
      if (setDates) {
        if (resStart) {
          this.prevStartDate = new Date(this.startDate);
        }
        if (resEnd) {
          this.prevEndDate = new Date(this.endDate);
        }
      }
      return resStart || resEnd;
    },

    isStartDateDifferent: function() {
      return (
        this.prevStartDate === null ||
        this.startDate.toLocaleString() !== this.prevStartDate.toLocaleString()
      );
    },

    isEndDateDifferent: function() {
      return (
        this.prevEndDate === null ||
        this.endDate.toLocaleString() !== this.prevEndDate.toLocaleString()
      );
    },

    calculateTotals: function() {
      if (this.jobs.length) {
        this.resetTotalsData();
        var that = this;

        this.jobs.forEach(function(job) {
          that.totalRows += job.totalRows;
          that.totalRowsOk += job.totalRowsOk;
          that.totalCredits += job.cost;
          //If we don't have finalPrice, used, estimated one
          that.totalCost += job.price;
          that.totalSavings += job.estimatedPrice - job.price;
        });
      }
    },

    resetTotalsData: function() {
      this.totalRows = 0;
      this.totalRowsOk = 0;
      this.totalCost = 0;
      this.totalCredits = 0;
      this.totalSavings = 0;
    },

    jobTotals: function() {
      this.updateTotalsData();
      //this.getSortingValues();
    },

    updateTotalsData: function() {
      this.totalRows = this.getTotalRows();
      this.totalRowsOk = this.getTotalRowsOk();
      this.totalCost = this.getTotalCost();
      this.totalSavings = this.getTotalSavings();
      this.totalCredits = this.getTotalCredits();
    },

    getTotalRows: function() {
      return this.totalRows;
    },

    getTotalRowsOk: function() {
      return this.totalRowsOk;
    },

    getTotalCost: function() {
      return this.totalCost;
    },

    getTotalSavings: function() {
      return this.totalSavings;
    },

    getTotalCredits: function() {
      return this.totalCredits;
    },

    downloadOriginal: function(id) {
      window.location.href = this.getOriginalFileUrl(id);
    },

    downloadOriginalSigned: function(id) {
      this.getOriginalFileSignedUrl(id);
    },

    getOriginalFileSignedUrl: function(jobId) {
      var url =
        this.$apiBase + "/api/v2/job/" + jobId + "/downloadsigned/original";
      this.$http.get(url, this.config).then((results) => {
        if (
          results &&
          results.data &&
          results.data.url &&
          results.data.url.length
        ) {
          window.location.href = results.data.url;
        }
      });
    },

    getOriginalFileUrl: function(jobId) {
      return (
        this.$apiBase +
        "/api/v1/" +
        localStorage.token +
        "/job/download/" +
        jobId +
        "/original"
      );
    },

    downloadResults: function(id) {
      window.location.href = this.getResultsFileUrl(id);
    },

    getResultsFileUrl: function(jobId) {
      return (
        this.$apiBase +
        "/api/v1/" +
        localStorage.token +
        "/job/download/" +
        jobId +
        "/results-final"
      );
    },

    downloadResultsSigned: function(id) {
      this.getResultsFileSignedUrl(id);
    },

    getResultsFileSignedUrl: function(jobId) {
      var url =
        this.$apiBase +
        "/api/v2/job/" +
        jobId +
        "/downloadsigned/results-final";
      this.$http.get(url, this.config).then((results) => {
        if (
          results &&
          results.data &&
          results.data.url &&
          results.data.url.length
        ) {
          window.location.href = results.data.url;
        }
      });
    },

    updatePaginationValues: function() {
      //this.currentPage = this.getCurrentPage() + 1;
      this.totalPages = this.getTotalPages();
    },

    getCurrentPage: function() {
      return this.currentPage;
    },

    getTotalPages: function() {
      return this.totalPages;
    },

    isFirstPage: function() {
      return (
        (this.totalPages > 0 && this.currentPage === 0) ||
        (this.totalPages === 0 && this.currentPage === 0)
      );
    },

    isLastPage: function() {
      return (
        (this.totalPages > 0 && this.currentPage === this.totalPages - 1) ||
        (this.totalPages === 0 && this.currentPage === 0)
      );
    },

    moveNext: function() {
      this.clearCountdown();
      if (this.isLastPage()) {
        this.loading = true;
      }
      if (!this.isLastPage()) {
        this.currentPage++;
        this.getJobs(true);
      }
    },

    movePrevious: function() {
      this.clearCountdown();
      if (this.isFirstPage()) {
        this.loading = true;
      }
      if (!this.isFirstPage()) {
        this.currentPage--;
        this.getJobs(true);
      }
    },
  },
};
</script>