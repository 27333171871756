<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="pb-4">
        <a v-on:click="back">
          {{ $t("back") }}
        </a>
      </div>
      <h4>{{ $t("add") }}</h4>
      <div>
        <div class="row pt-4">
          <div class="col-lg-12">
            <p class="pb-4">{{ $t("tables-add-description") }}</p>
            <div for="tableName">{{ $t("tables-name") }}</div>
            <input
              type="text"
              id="tableName"
              v-model="tableName"
              class="form-control"
              v-on:focus="$event.target.select()"
            />
            <div for="file">{{ $t("tables-upload") }}</div>
            <input
              type="file"
              id="file"
              ref="file"
              class="form-control"
              @change="handleFileUpload"
            />
            <button type="submit" class="btn btn-primary" v-on:click="createTable">
              {{ $t("tables-create") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "add",
  props: ["user", "settings"],
  data() {
    return {
      tableName: "Table " + new Date().toISOString().slice(0, 10).replace(/-/g, ""),
      file: null,

      hasToBeSent: false,
    };
  },
  mounted() {},
  components: {},
  methods: {
    back: function () {
      this.$emit("change-section", "list");
    },

    getConfigAuth: function () {
      return {
        headers: {
          Authorization:
            "Basic " +
            btoa(localStorage.configEmail + ":" + localStorage.token),
          "Content-Type": "application/json",
        },
      };
    },

    handleFileUpload: function (e) {
      this.file = e.target.files[0];
    },

    createTable: function () {
      this.$emit("change-section", "list");
    },

    hasToBeSaved: function () {
      if (JSON.stringify(this.user) === JSON.stringify(this.FromServerUser)) {
        this.hasToBeSent = false;
      } else {
        this.hasToBeSent = true;
      }
    },

    
    getUserDetails: function () {
      var url = "/api/v2/profile";
      this.$http.get(url, this.getConfigAuth()).then((results) => {
        this.user = results.data;
      });
    },
  },
};
</script>
